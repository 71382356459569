import React, { JSX } from "react";

import { TABLE_FILTER_DELETE } from "graphql/mutation/common-m.gql";

import LayoutButtons from "../../buttons";
import Icons from "../../icons";
import Menus from "../../menus";
import { reactiveVarHelper } from "common/helpers";
import { DeleteButton } from "components/service";
import Localize, { localizeByKey } from "components/service/Localize";

import type { TFilterStatus, TID } from "graphql/type/common-gql-types";
import type { TUser } from "graphql/type/user-gql-types";


interface IFiltersPresetListActionsProps{
    filterPreset   : {
        id      : TID
        name    : string
        status  : TFilterStatus
        user_id : TID
    }
}

const FiltersPresetListActions : React.FC<IFiltersPresetListActionsProps> = (
    {
        filterPreset,
    }) : JSX.Element => {

    const me = reactiveVarHelper().get('me') as TUser;

    if(filterPreset.user_id !== me.id || filterPreset.status === 'default'){
        return <Icons.Lock className="lock" />;
    }

    return (
        <Menus.Popover zIndex = { 3070 }>
            <LayoutButtons.FilterPresetChangeStatus
                { ...filterPreset }
            />
            <DeleteButton
                className   = { "filter-preset-delete" }
                buttonType  = { "text" }
                id          = { filterPreset.id }
                query       = { TABLE_FILTER_DELETE }
                reFetch     = { [ "TableFilterTypeRequests" ] }
                zIndex      = { 5000 }
                confirmText = {
                    localizeByKey(
                        'GLOBAL.Modal_Text_DeleteFilterPreset',
                        { filterPresetName : filterPreset.name }
                    )
                }
            >
                <Localize>GLOBAL.Action_Menu_DeletePreset</Localize>
            </DeleteButton>
        </Menus.Popover>
    );
};

export default FiltersPresetListActions;