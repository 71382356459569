import React, { JSX, useEffect } from "react";

import { Form, FormInstance, Input, Select } from "antd";

//import LayoutHooks from "../hooks";
import Icons from "../icons";
import { reactiveVarHelper } from "common/helpers";
import Team from "components/team";

import type { TFilterTypeName } from "common/types";
import type { TTableFilterInput } from "graphql/type/common-gql-types";
import type { TUser } from "graphql/type/user-gql-types";


interface IFiltersPresetSaveFormProps {
    query :  TFilterTypeName
    setCallback ?: (
        props : {
            callback  : () => void
        }
    ) => void
    loading ?: boolean
    onSubmit : ({ variables } : { variables : { input : TTableFilterInput } }) => void
    setForm : ( form : FormInstance ) => void
}

const FiltersPresetSaveForm : React.FC<IFiltersPresetSaveFormProps> = (
    {
        query,
        //setCallback
        loading,
        onSubmit,
        setForm
    }) : JSX.Element => {

    //const { tableFilterAction, loading } = LayoutHooks.mutation.filterPreset({ isCreate : true });

    const me : TUser = reactiveVarHelper().get('me');

    const [ form ] = Form.useForm();

    useEffect(() => {
        setForm(form);
    }, []);
    // useEffect(() => {
    //     if(setCallback){
    //         setCallback( {
    //             callback : () => {
    //                 form.submit();
    //             },
    //         } );
    //     }
    //
    // }, []);


    return (
        <Form
            className={"inner-forms"}
            form={ form }
            layout="vertical"
            onFinish={(values) => {
                const data = localStorage.getItem('tmpFilterFields');

                if (!loading && data ) {
                    onSubmit({
                        variables : {
                            input : {
                                user_id : me.id,
                                query,
                                data,
                                ...values
                            }
                        }
                    });
                }
            }}
        >
            <Form.Item
                label  = {"Filter preset name"}
                name   = "title"
                rules  = {[ {
                    min : 6,
                    required : true,
                    message : "Min length 6 letters"
                } ]}
            >
                <Input name="query-name" />
            </Form.Item>
            <Form.Item
                label = {"Access"}
                name  = "status"
                rules = {[ {
                    required : true,
                    message : "required"
                } ]}
                initialValue={ 'private' }
            >
                <Select
                    suffixIcon = { <Icons.Arrow /> }
                >
                    <Select.Option key="private">Private</Select.Option>
                    <Select.Option key="public">Public</Select.Option>
                </Select>
            </Form.Item>
            { !!me.teams.length &&
                <Form.Item
                    label = { "Team" }
                    name  = "team_id"
                >
                    <Team.Fields.Select
                        existTeams = { me.teams.map( ( item ) => item.id ) }
                    />
                </Form.Item>
            }
        </Form>
    );
};

export default FiltersPresetSaveForm;