import React, { JSX } from "react";

import ExhibitionFields from "../fields";
import ExhibitionHelpers from "../helpers";
import { reactiveVarHelper } from "common/helpers";
import { Blocks } from "components/layout";
import { localizeByKey } from "components/service/Localize";
import Team from "components/team";

import { ETeamRoles } from "common/types";
import { EExhibitionFilterFieldTypes, type TExhibitionFilters } from "components/exhibition/exhibition-types";
import type { TTeamMember } from "components/team/team-types";
import type { IFilterReactiveVar } from "graphql/reactive-variables";
 

type TExhibitionListFilterProps = {
    filters       : TExhibitionFilters,
    setFilters    : React.Dispatch<React.SetStateAction<TExhibitionFilters>>
    callBack     ?: () => void
};

const ExhibitionListFilterForm : React.FC<TExhibitionListFilterProps> = (
    {
        filters,
        setFilters,
        callBack = () => {},
    }) : JSX.Element => {

    const rVarFilter = reactiveVarHelper('filter');
    const { ExhibitionList : preset } = rVarFilter.get('currentPresets') as IFilterReactiveVar["currentPresets"];


    return (
        <Blocks.Filter.Form
            filterFields     = { ExhibitionHelpers.filter.parse(filters) }
            fieldTypes       = { EExhibitionFilterFieldTypes }
            defaultFiledType = { EExhibitionFilterFieldTypes.NONE }
            translationKey   = { 'EXHIBITION.Select_Option_Filter' }
            filterTypeName   = { "ExhibitionList" }
            onReset          = {
                () => {
                    setFilters({ text : '' });
                    callBack();
                }
            }
            onChange = {
                (fields) => {
                    localStorage.setItem(
                        "tmpFilterFields",
                        JSON.stringify({
                            text : filters.text,
                            ...ExhibitionHelpers.filter.build(fields)
                        })
                    );
                }
            }
            onApply  = {
                (fields, saveFilter ) => {
                    const newFilterData = JSON.stringify({
                        text : filters.text,
                        ...ExhibitionHelpers.filter.build(fields)
                    });

                    // TODO check why filterPreset data not updated
                    if(preset?.data !== newFilterData){
                        saveFilter( newFilterData );
                    }

                    setFilters({
                        text : filters.text,
                        ...ExhibitionHelpers.filter.build(fields)
                    });

                    callBack();

                }
            }
        >
            {
                ({
                     fieldSettings,
                     filteredTypes,
                     fieldItem : { type, value, label },
                }) => {

                    switch (type){
                        case EExhibitionFilterFieldTypes.STATUS:
                            return (
                                <Blocks.Filter.Item<EExhibitionFilterFieldTypes> { ...fieldSettings }>
                                    <ExhibitionFields.StatusSelect/>
                                </Blocks.Filter.Item>
                            );
                        case EExhibitionFilterFieldTypes.ORGANIZER:
                            return (
                                <Blocks.Filter.Item<EExhibitionFilterFieldTypes> { ...fieldSettings }>
                                    <ExhibitionFields.OrganizerSelect />
                                </Blocks.Filter.Item>
                            );
                        case EExhibitionFilterFieldTypes.EXHIBITION_TYPE:
                            return (
                                <Blocks.Filter.Item<EExhibitionFilterFieldTypes> { ...fieldSettings }>
                                    <ExhibitionFields.TypeSelect
                                        organizerId  = {
                                            filteredTypes.includes(EExhibitionFilterFieldTypes.ORGANIZER)
                                                ? fieldSettings.fields.find(
                                                    ({ type }) => type === EExhibitionFilterFieldTypes.ORGANIZER )?.value || 0 : 0
                                        }
                                        filterClosed = { false }
                                    />
                                </Blocks.Filter.Item>
                            );
                        case EExhibitionFilterFieldTypes.TEAM:
                            return (
                                <Blocks.Filter.Item<EExhibitionFilterFieldTypes> { ...fieldSettings }>
                                    <Team.Fields.Select />
                                </Blocks.Filter.Item>
                            );
                        case EExhibitionFilterFieldTypes.PROJECT_MANAGER:
                            return (
                                <Blocks.Filter.Item<EExhibitionFilterFieldTypes> { ...fieldSettings }>
                                    <Team.Fields.UserSelect
                                        modalTitle    = {
                                            localizeByKey('USER.Modal_Title_ChooseProjectManager')
                                        }
                                        teamRole      = { ETeamRoles.PROJECT_MANAGER }
                                        currentMember = {
                                            value && label ?
                                                {
                                                    id : Number(value),
                                                    name : label.split(' ')[0],
                                                    surname : label.split(' ')[1]
                                                } as TTeamMember : undefined
                                        }
                                    />
                                </Blocks.Filter.Item>
                            );
                        case EExhibitionFilterFieldTypes.NONE:
                        default:
                            return (
                                <Blocks.Filter.Item<EExhibitionFilterFieldTypes>
                                    { ...fieldSettings }
                                    noValue = { true }
                                >
                                    <ExhibitionFields.FilterTypeSelect
                                        filteredTypes = { filteredTypes as EExhibitionFilterFieldTypes[]}
                                        defaultValue  = {
                                            value && value.length ?
                                                value as EExhibitionFilterFieldTypes :
                                                type as EExhibitionFilterFieldTypes
                                        }
                                    />
                                </Blocks.Filter.Item>
                            );
                    }
                }}
        </Blocks.Filter.Form>
    );
};

export default ExhibitionListFilterForm;