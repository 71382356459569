import StandsEquipmentListTable from "./Stands-equipment-list-table";
import StandsTable from "./Stands-table";

import "./stand-tables.scss";


const StandTables = {
   All : StandsTable,
   EquipmentList : StandsEquipmentListTable
};

export default StandTables;