import { makeVar, ReactiveVar } from "@apollo/client";

import { IFilterReactiveVar } from "./reactive-var-interfaces";

export const filterParamsInitial: IFilterReactiveVar = {
    metaFilters : [],
    currentPresets : {},
    defaultPresets : {},
    tmpFilterData : undefined
};

export const filterReactiveVar : ReactiveVar<IFilterReactiveVar> = makeVar<IFilterReactiveVar>(filterParamsInitial);