import type { TWhereCondition } from "graphql/type/common-gql-types";


export type TTaskFilterGroups = 'where' | 'whereStand' | 'whereCad';
export type TTaskFilterFields = Partial<Record<`${ETaskFilterFieldTypes}`, TWhereCondition>>;

export type TTaskFilters = Partial<Record<TTaskFilterGroups, TTaskFilterFields>> & Record<'text', string>;

export enum ETaskFilterFieldTypes  {
    NONE = 'none',
    COMPANY_NAME = 'companyName',
    HALL = 'hall',
    BLOCK = 'block',
    STAND_NUMBER = 'standNumber',
    PROJECT_MANAGER = 'projectManager',
    // AUTOCAD_ENGINEER = 'autocadEngineer',

    CAD_STATUS = 'cadStatus',
    CAD_NUMBER = 'cadNumber',

    TASK_IMPORTANCE = 'taskImportance',
    TASK_STATUS = 'taskStatus',
    TASK_TYPE = 'taskType',
    TASK_ASSIGNED_TO = 'taskAssignedTo',


}