import React, { JSX }  from "react";

import { useOutletContext } from "react-router-dom";

import CheckList from "components/check-list";

import type { TCheckListGroups } from "components/check-list/check-list-types";

 
const CheckListInvoiceSubPage : React.FC = () : JSX.Element => {

    const { ee, checkLists } = useOutletContext<{ee : any, checkLists: TCheckListGroups  }>();

    const { fieldGroups } = checkLists.Invoice;

    return(
        <div className="page-content">
            <CheckList.Forms.Invoice
                eeId      = { ee.id }
                listGroup = "Invoice"
                formData  = { fieldGroups.Invoice }
            />
        </div>
    );
};

export default CheckListInvoiceSubPage;