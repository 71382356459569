import EquipmentBlocks from "./blocks";
import EquipmentFields from "./fields";
import EquipmentForms from "./forms";
import EquipmentHelpers from "./helpers";
import EquipmentLists from "./lists";
import EquipmentModals from "./modals";
import EquipmentTables from "./tables";


const Equipment  = {
    Blocks : EquipmentBlocks,
    Lists : EquipmentLists,
    Helpers : EquipmentHelpers,
    Tables : EquipmentTables,
    Modals : EquipmentModals,
    Forms : EquipmentForms,
    Fields : EquipmentFields
};

export default Equipment;