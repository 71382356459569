import FilterPresetChangeStatusButton from "./Filter-preset-change-status-button";
import PageBackButton from "./Page-back-button";

import "./layout-buttons.scss";


const LayoutButtons = {
    PageBack : PageBackButton,
    FilterPresetChangeStatus : FilterPresetChangeStatusButton
};

export default LayoutButtons;