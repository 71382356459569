import React, { JSX } from "react";

import Fields from "../../fields";
import Menus from "../../menus";
import Modals from "../../modals";
import { subFieldCount } from "common/utils";

import type { IFiltersBlocksProps } from "common/types";


interface IFiltersBlockProps<FiltersType> extends IFiltersBlocksProps<FiltersType>{
    children : React.ReactNode
}

const FiltersBlock  = <FiltersType, >(
    {
        children,
        ...props
    } : IFiltersBlockProps<FiltersType>) : JSX.Element => {

    return (
        <>
            <Fields.Search<FiltersType>
                { ...props.state }
            />
            <Modals.Filters
                filtersCount = { subFieldCount(props.state.filters) }
                presetMenu   = {
                    <Menus.FilterPreset<FiltersType>
                        isSave   = { true }
                        btnType  = "text"
                        btnClass = ''
                        { ...props }
                    />
                }
            >
                { children }
            </Modals.Filters>
            <Menus.FilterPreset<FiltersType>
                isSave   = { false }
                btnClass = 'inverted'
                { ...props }
            />
        </>
    );
};

export default FiltersBlock;