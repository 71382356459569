import { filterParamsInitial, filterReactiveVar } from "./filter-reactive-var";
import { privateParamsInitial, privateReactiveVar } from "./private-reactive-var";
import { publicParamsInitial, publicReactiveVar } from "./public-reactive-var";

import type {
    IFilterReactiveVar,
    IPrivateReactiveVar,
    IPublicReactiveVar,
    TNotificationState
} from "./reactive-var-interfaces";


const vars = {
    public : {
        data : publicParamsInitial,
        handler : publicReactiveVar
    },
    private : {
        data : privateParamsInitial,
        handler : privateReactiveVar
    },
    filter : {
        data : filterParamsInitial,
        handler : filterReactiveVar
    },
};


export type{
    IPrivateReactiveVar,
    IPublicReactiveVar,
    IFilterReactiveVar,
    TNotificationState
};

export default vars;