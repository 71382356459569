import ExhibitionMenu from "./exhibition-menu";
import FilterPresetMenu from "./filter-preset-menu";
import MainMenu from "./main-menu";
import PopoverMenu from "./popover-menu";
import ThirdLvlMenu from "./third-lvl-menu";
import UserMenu from "./user-menu";

import "./menus.scss";


const LayoutMenus = {
    Main : MainMenu,
    User : UserMenu,
    Exhibition : ExhibitionMenu,
    ThirdLvl : ThirdLvlMenu,
    Popover : PopoverMenu,
    FilterPreset : FilterPresetMenu
};

export default LayoutMenus;