import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";


const EquipmentListSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M9.83,25.76l13.34-8.42,15.5,9.79-13.46,8.72c-1.24.8-2.84.8-4.08,0l-11.31-7.33c-1-.65-.99-2.12.02-2.76Z"/>
        <g className="cls-1">
            <path d="M38.66,12.8c0-1.11-.57-2.15-1.52-2.74l-11.76-7.37c-.97-.61-2.22.09-2.22,1.23v13.41l15.5,9.79v-14.32Z"/>
            <path d="M38.63,27.15l-4.05,2.87v7.6c0,1.26.93,2.27,2.07,2.27s2.07-1.02,2.07-2.27v-9.92c0-.2-.06-.38-.1-.56Z"/>
            <path d="M25.2,35.85c-1.22.79-2.79.8-4.02.03,0,.06-.04.12-.04.18v8.81c0,1.15.93,2.08,2.07,2.08s2.08-.93,2.08-2.08v-8.81c0-.08-.04-.16-.05-.24l-.04.03Z"/>
            <path d="M9.08,27.21c-.03.15-.09.29-.09.44v9.05c0,1.15.93,2.08,2.08,2.08s2.07-.93,2.07-2.08v-6.03l-3.33-2.16c-.48-.31-.71-.8-.73-1.3Z"/>
        </g>
    </svg>
);


const EquipmentListIcon : FunctionComponent<IIcon> = ({ className }) : JSX.Element => {

    return <Icon component={ EquipmentListSvg } className={ className } />;

};

export default EquipmentListIcon;