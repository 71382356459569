import useFilterPresetHook from "./use-filter-preset-hook";
import useFilterPresetMutationHook from "./use-filter-preset-mutation-hook";
import useFilterResetHook from "./use-filter-reset-hook";
import useFilterSaveHook from "./use-filter-save-hook";
import useMetaSetHook from "./use-meta-set-hook";


const LayoutHooks = {
    query : {
        filterPreset : useFilterPresetHook
    },
    mutation : {
        filterPreset : useFilterPresetMutationHook,
        filterSave : useFilterSaveHook,
        filterReset : useFilterResetHook,
        metaSet : useMetaSetHook
    }
};

export default LayoutHooks;