import React, { JSX, ReactNode } from "react";

import { DocumentNode, useMutation } from "@apollo/client";
import { ApolloCache } from "@apollo/client/cache";
import { getOperationName } from "@apollo/client/utilities";
import { App, Button } from "antd";
import { generatePath, useNavigate } from "react-router-dom";

import Localize, { localizeByKey } from "./Localize";
import { Icons } from "components/layout";
import { ROUTES } from "components/routes";
import { useNotification } from "components/use-hook";

import type { ButtonType } from "antd/lib/button";
import type { TRouteParams } from "common/types";
import type { TID } from "graphql/type/common-gql-types";


type TDeleteProps = {
    id           : number | TID
    query        : DocumentNode
    children    ?: ReactNode
    route       ?: keyof typeof ROUTES | undefined
    routeParams ?: TRouteParams
    className   ?: string | undefined
    iconName    ?: keyof typeof Icons
    buttonType  ?: ButtonType
    reFetch     ?: string[] | undefined
    confirmText ?: string | undefined
    onSuccess   ?: ( data : any, cache : ApolloCache<any>  ) => void
    zIndex      ?: number
    variables   ?: any
};

const DeleteButton : React.FC<TDeleteProps> = (
    {
        id,
        children,
        query,
        route ,
        className,
        iconName = 'Delete',
        buttonType,
        zIndex = 2075,
        routeParams,
        reFetch,
        confirmText,
        onSuccess,
        variables
    }) : JSX.Element => {

    const { modal } = App.useApp();

    const navigate = useNavigate(),
          { nError, nSuccess } = useNotification();

    const [ itemDelete, { loading } ] = useMutation( query,
        {
            update(cache, { data }) {

                const operation = getOperationName(query);

                const { [ operation! ] : {
                    label,
                    message,
                }  } = data;

                if(route){
                    navigate(
                        routeParams ? generatePath( ROUTES[ route ], routeParams ) : ROUTES[ route ]
                    );
                }

                onSuccess && onSuccess(data, cache);

                nSuccess(label, message);

            },
            refetchQueries : reFetch
        }
    );

    const Icon = Icons[iconName];

    return(
        <Button
            className = { `delete-button ${ className || "" }` }
            type      = { buttonType! }
            onClick   = {
                () => {
                    if(!loading){

                        modal.confirm({
                            zIndex : zIndex,
                            title : confirmText || <Localize>GLOBAL.Modal_DefaultText_Delete</Localize>,
                            icon : null,
                            className : "modal-confirm",
                            centered : true,
                            okText : localizeByKey('GLOBAL.Button_Label_Confirm'),
                            onOk() {
                                const delVar = variables ? { ...variables } : { id };

                                itemDelete( {
                                    variables : { ...delVar }
                                }).catch( nError );
                            }
                        });

                    }
                }
            }
        >
            <Icon loading={ loading } />
            { children }
        </Button>
    );
};

export default DeleteButton;