import React, { JSX }  from "react";

import { Select } from "antd";
import { SelectProps } from "rc-select/lib/Select";

import { EEquipmentFilterFieldTypes } from "../equipment.types";
import { Icons } from "components/layout";
import Localize from "components/service/Localize";


type TGraphicsListFilterSelectProps = {
    filteredTypes : EEquipmentFilterFieldTypes[]
    defaultValue  : EEquipmentFilterFieldTypes
    onChange     ?: SelectProps['onChange']
};

const { Option } = Select;

const EquipmentListFilterTypesSelect : React.FC<TGraphicsListFilterSelectProps> = (
    {
        filteredTypes,
        defaultValue ,
        ...props
    }): JSX.Element => {

    return(
        <Select
            className     = { `equipment-list-filter-type-select` }
            suffixIcon    = { <Icons.Arrow /> }
            defaultValue  = { defaultValue }
            { ...props }
        >
            <Option key={ EEquipmentFilterFieldTypes.NONE } value={ EEquipmentFilterFieldTypes.NONE  }>
                <Localize>GLOBAL.Placeholder_Text_NotSelected</Localize>
            </Option>
            {(Object.keys(EEquipmentFilterFieldTypes) as Array<keyof typeof EEquipmentFilterFieldTypes>)
                .filter( ( item ) => {
                    return !filteredTypes.includes(EEquipmentFilterFieldTypes[item]);
                } )
                .map(
                    (key) => {
                        if(key === 'NONE') return null;

                        return(
                            <Option key={ key } value={ EEquipmentFilterFieldTypes[key] }>
                                <Localize>{ `EQUIPMENT.Select_Option_Filter_${ EEquipmentFilterFieldTypes[key] }` }</Localize>
                            </Option>
                        );
                    })
            }
        </Select>
    );
};

export default EquipmentListFilterTypesSelect;