import React, { JSX } from "react";

import { useQuery } from "@apollo/client";
import { Select } from "antd";

import { GET_TEAMS } from "graphql/query/team-q.gql";

import { Icons } from "components/layout";
import Localize from "components/service/Localize";

import type { TID } from "graphql/type/common-gql-types";


interface ITeamSelectProps {
    existTeams ?: TID[]
}

const TeamSelect : React.FC<ITeamSelectProps> = ({
        existTeams = [],
        ...props
    }) : JSX.Element => {

    const { data : { teamsCursor } = {}, loading } = useQuery( GET_TEAMS, {
        variables : {
            orderBy : [ { column : "TITLE", order : "ASC" } ]
        },
        fetchPolicy : "cache-first"
    });

    const { edges  = [] } = teamsCursor || {};
    let teams = edges;

    if(existTeams.length > 0){
        teams = teams.filter(({ node  } ) => existTeams.includes(node.id));
    }

    return(
        <Select
            loading      = { loading }
            defaultValue = { ""! }
            suffixIcon   = { <Icons.Arrow /> }
            { ...props }
        >
            <Select.Option key={ 0 } value={ "" }>
                <Localize>GLOBAL.Placeholder_Text_NotSelected</Localize>
            </Select.Option>

            { teams.map(({ node : { id, title } }) : JSX.Element => {

                    return(
                        <Select.Option
                            key   = { id }
                            value = { id }
                        >
                            { title }
                        </Select.Option>
                    );
                }
            )}
        </Select>
    );
};

export default React.memo(TeamSelect);