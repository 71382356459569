import React, { JSX, useState } from "react";

import { Button/*, FormInstance */ } from "antd";

import LayoutBlocks from "../../blocks";
import LayoutHooks from "../../hooks";
import LayoutList from "../../lists";
import { reactiveVarHelper } from "common/helpers";
import { Icons } from "components/layout";
import { ModalStandard } from "components/service";
import Localize, { localizeByKey } from "components/service/Localize";

import type { IFiltersBlocksProps } from "common/types";
import type { IFilterReactiveVar } from "graphql/reactive-variables";
import type { TTableFilterItem } from "graphql/type/common-gql-types";
import type { TUser } from "graphql/type/user-gql-types";


interface IFiltersPresetModalProps<FiltersType> extends IFiltersBlocksProps<FiltersType>{
    modalType   : 'Use' | 'Save'
    btnClass   ?: string
}

const FiltersPresetModal = <FiltersType,  >(
    {
        name,
        btnClass = "",
        modalType = "Use",
        state,
    } : IFiltersPresetModalProps<FiltersType>) : JSX.Element => {

    const [ openModal, setOpenModal ] = useState( false ),
          [ confirmLoading, setConfirmLoading  ]  = useState(false),
          [ currentItem, setCurrentItem ] = useState<TTableFilterItem | undefined>(undefined),
          [ onConfirm, setOnConfirm ] = useState({
              callback : () => {}
          });

    const me = reactiveVarHelper().get('me') as TUser,
          rVarFilter = reactiveVarHelper('filter'),
          metaFilters : IFilterReactiveVar["metaFilters"] = rVarFilter.get('metaFilters') ,
          currentMeta = metaFilters.find(
              ( item ) => item.meta_group === 'currentFilter' && item.meta_key === name
          );
          // [
          //     form,
          // //    setForm
          // ] = useState<FormInstance | undefined>(undefined);

    const { loading : loadingMetaSave, metaSet } = LayoutHooks.mutation.metaSet({
        onUpdate : () => {
            const data = localStorage.getItem('tmpFilterFields');

            if(data){
                state.setFilters({
                    ...state.filters,
                    ...JSON.parse(data)
                });
            }
        }
    });

    //console.log(currentMeta);

    return(
        <>
            <Button
                type      = "text"
                className = { btnClass }
                onClick   = { () => {
                    setOpenModal(true);
                }}
            >
                { modalType === 'Use' ?
                    <Icons.SettingPreset /> : <Icons.Plus />
                }

                <Localize wrap>
                    { `GLOBAL.Button_Label_${ modalType }FiltersPreset` }
                </Localize>
            </Button>
            <ModalStandard
                title      = {
                    <Localize wrap tag="h2">
                        { `GLOBAL.Modal_Title_${ modalType }FiltersPreset` }
                    </Localize>
                }
                isOpen     = { openModal }
                extraClass = { "filter-preset-modal" }
                close      = { () => setOpenModal(false) }
                okText     = { localizeByKey(`GLOBAL.Button_Label_${ modalType }`) }
                okButtonProps = {{
                    loading : confirmLoading || loadingMetaSave
                }}
                confirm    = {
                    () => {
                        if(modalType === 'Use'){
                            if(currentMeta && currentItem){
                                metaSet({
                                    variables : {
                                        input : {
                                            id : currentMeta.id,
                                            model : 'UserMeta',
                                            meta_group : currentMeta.meta_group,
                                            meta_key : currentMeta.meta_key,
                                            meta_type : currentMeta.meta_type,
                                            model_id : me.id,
                                            meta_value : currentItem.id
                                        }
                                    }
                                });
                            }

                            setOpenModal(false);
                        } else if(modalType === 'Save'){
                            setConfirmLoading(true);
                            onConfirm.callback();

                            setTimeout( () => {
                                setConfirmLoading(false);
                                setOpenModal(false);
                            }, 1000);
                        }

                        //form && form.submit();
                        //setOpenModal(false);
                    }
                }
            >
                { modalType === 'Use' &&
                   <LayoutList.FiltersPreset
                        query    = { name }
                        onChange = {
                            (selectedItem) => {
                                setCurrentItem(selectedItem);
                                localStorage.setItem('tmpFilterFields', selectedItem.data);
                            }
                        }
                   />
                }
                { modalType === 'Save' &&
                    <LayoutBlocks.FiltersPreset
                        query = { name }
                        setCallback = { setOnConfirm }
                    />
                }
            </ModalStandard>
        </>
    );
};

export default FiltersPresetModal;