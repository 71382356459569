import React, { JSX, useEffect, useState } from "react";

import { Button } from "antd";

import LayoutHooks from "../../hooks";
import { Icons } from "components/layout";
import Localize, { localizeByKey } from "components/service/Localize";

import type { TFilterFieldItem, TFilterTypeName } from "common/types";


type TFieldSettingsProps<FilterFields> = {
    key             : string
    itemKey         : string
    fields          : TFilterFieldItem<FilterFields>[]
    setFields       : React.Dispatch<React.SetStateAction<TFilterFieldItem<FilterFields>[]>>
    isFieldSelector : boolean
    label           : string
    onChange        : (result : string |  React.ChangeEvent<HTMLInputElement>, option : any) => void
};

type TFilterFormProps<FilterFields, FieldTypes> = {
    filterFields       : TFilterFieldItem<FilterFields>[],
    fieldTypes         : FieldTypes
    filterTypeName     : TFilterTypeName
    defaultFiledType   : any
    translationKey     : string
    onReset            : () => void
    onChange           : ( fields : TFilterFieldItem<FilterFields>[] ) => void
    onApply            : (
        fields : TFilterFieldItem<FilterFields>[],
        callBack : ( data : string ) => void
    ) => void
    readonly children  : ( props : {
        fieldSettings : TFieldSettingsProps<FilterFields>
        fieldItem     : Partial<TFilterFieldItem<FilterFields>>
        filteredTypes : (string | FilterFields)[]
    }) => JSX.Element
};

const FilterFormBlock  = <FilterFields, FieldTypes extends Record<string | 'none', string|number>>(
    {
        filterFields,
        fieldTypes,
        defaultFiledType,
        translationKey,
        filterTypeName,
        onReset,
        onChange = () => {},
        onApply,
        children
    } : TFilterFormProps<FilterFields, FieldTypes>) : JSX.Element => {

    const [
        fields,
        setFields
    ] = useState<TFilterFieldItem<FilterFields>[]>( filterFields );

    const {
        loading : saveLoading,
        filterSave
    } =  LayoutHooks.mutation.filterSave({ filterTypeName });

    const { loading : resetLoading, filterReset } = LayoutHooks.mutation.filterReset({ filterTypeName });

    useEffect(() => {

        if(fields.length > 0){
            onChange(fields);
        }

    }, [ fields ]);

    useEffect(() => {
        setFields(filterFields);
    }, [ filterFields ]);

    const filteredTypes = fields.map(
        (item) =>
            item.type === 'none' && item.value ? item.value : item.type)
        .filter(
            (item, idx, items) => idx === items.indexOf(item)
        );

    const typesCount = Object.keys(fieldTypes).length - (filteredTypes.includes(defaultFiledType) ? 0 : 1);

    return(
        <div className="filter-form-block">
            <div className="add-filter-wrap">
                <Button
                    type     = "primary"
                    disabled = { filteredTypes.length >= typesCount }
                    onClick  = {
                        () => {
                            setFields([
                                ...fields ,
                                {
                                    key : Date.now().toString(),
                                    type : defaultFiledType,
                                    label : '',
                                    value : '',
                                    saveable : false
                                }
                            ]);
                        }
                    }
                >
                    <Icons.Plus />
                    <Localize wrap>Add Filter</Localize>
                </Button>
            </div>
            <div className="filter-field-list">
                { fields.map(
                    ({ type , value, label, key }, idx) => {
                        const itemKey = `${ key }_${ idx }`;

                        const fieldSettings : TFieldSettingsProps<FilterFields> = {
                            key : itemKey, itemKey,
                            fields, setFields,
                            label : localizeByKey(
                                type !== 'none' ? `${translationKey}_${ type }` : 'GLOBAL.Field_Label_ChooseFilter'
                            ),
                            isFieldSelector : type === defaultFiledType,
                            onChange : (result, option) => {
                                setFields(
                                    fields.map((item, fieldIdx) => {
                                        return fieldIdx === idx ?
                                            {
                                                ...item,
                                                value : typeof result === 'string' ? result : result.target.value,
                                                ...(option && { label : option.label })
                                            } : item;
                                    })
                                );
                            }
                        };

                        return children({
                            fieldSettings,
                            fieldItem : {
                                type,
                                value,
                                label
                            },
                            filteredTypes,
                        });
                    }
                )}
            </div>
            <div className="form-actions">
                <Button
                    type     = "default"
                    loading  = { resetLoading }
                    onClick  = {
                        () => {
                            if(!resetLoading){
                                filterReset();
                                setFields([]);
                                onReset();
                            }

                        }
                    }
                >
                    <Localize wrap>Reset</Localize>
                </Button>
                <Button
                    type      = "primary"
                    disabled  = {
                        (fields.map((item) => item.type)
                            .filter((item, idx, items) => idx === items.indexOf(item))
                            .filter((item) => item !== 'none')).length < 1 || saveLoading
                    }
                    loading  = { saveLoading }
                    onClick  = {
                        () => {
                            onApply(
                                fields,
                                (data) => filterSave(data)
                            );
                        }
                    }
                >
                    <Localize wrap>Apply</Localize>
                </Button>
            </div>
        </div>
    );
};

export default FilterFormBlock;