import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";
import { Loader } from "components/request-result";


const FilterPresetSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M8.69,11.68h25.85l-4.06,4.88c-.71.85-.59,2.11.26,2.82.85.71,2.11.59,2.82-.26l6.78-8.16c.5-.6.6-1.43.27-2.13-.33-.7-1.04-1.15-1.81-1.15H4.59c-.76,0-1.46.44-1.8,1.12s-.25,1.51.22,2.11l13.3,16.98v10.7c0,1.1.9,2,2,2s2-.9,2-2v-11.39c0-.45-.15-.88-.43-1.23l-11.19-14.29Z"/>
        <path d="M44.22,31.16l-1.99-.35c-.22-.85-.55-1.66-.99-2.4l1.16-1.66c.37-.37.37-.98,0-1.36l-1.29-1.29c-.37-.37-.98-.37-1.36,0l-1.66,1.16c-.74-.44-1.54-.78-2.4-1l-.35-1.99c0-.53-.43-.96-.96-.96h-1.83c-.53,0-.96.43-.96.96l-.35,1.99c-.85.22-1.66.56-2.4,1l-1.66-1.16c-.37-.37-.98-.37-1.36,0l-1.29,1.29c-.37.37-.37.98,0,1.36l1.16,1.66c-.44.74-.78,1.54-.99,2.4l-1.99.35c-.53,0-.96.43-.96.96v1.83c0,.53.43.96.96.96l1.99.35c.22.85.55,1.65.99,2.39l-1.16,1.66c-.37.37-.37.98,0,1.36l1.29,1.29c.37.37.98.37,1.36,0l1.66-1.16c.74.44,1.54.77,2.39.99l.36,2c0,.53.43.96.96.96h1.83c.53,0,.96-.43.96-.96l.36-2c.85-.22,1.65-.55,2.39-.99l1.66,1.16c.37.37.98.37,1.36,0l1.29-1.29c.37-.37.37-.98,0-1.36l-1.16-1.66c.44-.74.78-1.54.99-2.39l1.99-.35c.53,0,.96-.43.96-.96v-1.83c0-.53-.43-.96-.96-.96ZM33.46,37.58c-2.51,0-4.55-2.04-4.55-4.55s2.04-4.55,4.55-4.55,4.55,2.04,4.55,4.55-2.04,4.55-4.55,4.55Z"/>
    </svg>
);


const FilterPresetIcon: FunctionComponent<IIcon> = ({ className, loading }): JSX.Element => {

    if (loading) {
        return <Loader/>;
    }

    return <Icon component={FilterPresetSvg} className={className}/>;

};


export default FilterPresetIcon;