import React, { JSX, /*useState*/ } from "react";

import Exhibition from "components/exhibition";
import { DrawerFilter } from "components/service/";

import type { TID } from "graphql/type/common-gql-types";


export type TFilterProps = {
    eeId  ?: TID
    etId   : TID
    orgId  : TID
    text  ?: string
};

type TStandTypeFilterProps = {
    readonly children  : ( props ?: any ) => JSX.Element
    filterProps : TFilterProps
};

const { useHook } = Exhibition;


const EquipmentFilterBlock : React.FC<TStandTypeFilterProps> = ({ children, filterProps }) : JSX.Element => {

    const organizer = useHook.organizer({ orgId : Number(filterProps.orgId) }),
          exhibitionType =  useHook.exhibitionType({ etId : Number(filterProps.etId) });

    const filterOptions = [
        { value : 0, label : `Show Equipment for ${ exhibitionType.title } exhibition type`, filterFields : [ 'orgId' ] },
        { value : 1, label : `Show Equipment for ${ organizer.title } organizer`, filterFields : [ 'etId' ] },
        { value : 2, label : 'All Equipment', filterFields : [ "etId", 'orgId' ] },
    ];

    return(
        <DrawerFilter<TFilterProps>
            drawContent   = { (filteredProps) => children(filteredProps) }
            filterOptions = { filterOptions }
            filterProps   = { filterProps }
        />
    );
};

export default EquipmentFilterBlock;