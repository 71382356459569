
const subFieldCount = (obj : any) : number => {

    let count = 0;

    Object.keys(obj).forEach( ( group ) => {
        if(group !== 'text'){
            count += Object.keys(obj[group]).length;
        }
    });

    return count;
};

export default subFieldCount;