import React, { JSX, useState } from "react";

import { Button } from "antd";

import Icons from "../icons";
import { cloneChildren } from "common/utils";
import { DrawerStandard } from "components/service";
import Localize from "components/service/Localize";


interface FilterDrawerProps {
    presetMenu   ?: JSX.Element
    btnClass     ?: string
    filtersCount ?: number
    children     ?: React.ReactNode
}

const FiltersDrawer : React.FC<FilterDrawerProps> = (
    {
        presetMenu,
        btnClass = '',
        children,
        filtersCount = 0
    }): JSX.Element => {


    const [ openDrawer, setOpenDrawer ] = useState( false );

    const onClose = () => {
        setOpenDrawer(false);
        localStorage.removeItem("tmpFilterFields");
    };

    return (
        <>
            <Button
                type      = "default"
                className = { `filter-button ${ btnClass }` }
                onClick   = { () => setOpenDrawer(true) }
            >
                <Icons.Filter />
                <Localize
                    wrap count={
                        <b className={ filtersCount ? 'active' : '' }>
                            { filtersCount }
                        </b>
                    }
                >
                    GLOBAL.Button_Label_Filters
                </Localize>
            </Button>
            <DrawerStandard
                title = {
                    <>
                        <Localize
                            wrap tag = "h2"
                            count    = { filtersCount }
                        >
                            GLOBAL.Modal_Title_Filters
                        </Localize>
                        { presetMenu }
                    </>
                }
                isOpen     = { openDrawer }
                extraClass = { "filter-drawer" }
                close      = { onClose }
            >
                { cloneChildren(
                    children as React.ReactElement,
                    {
                        callBack : onClose
                    }
                )}
            </DrawerStandard>
        </>

    );
};

export default FiltersDrawer;