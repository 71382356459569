import { useQuery } from "@apollo/client";
import { WatchQueryFetchPolicy } from "@apollo/client/core/watchQueryOptions";

import { GET_TABLE_FILTER_REQUESTS } from "graphql/query/common-q.gql";

import type { IUseGraphQLHook, TFilterTypeName } from "common/types";
import { EWhereOperator, TFilterStatus, TID, TTableFilterItem } from "graphql/type/common-gql-types";


interface IUseVariableLists extends IUseGraphQLHook{
    filterPresets : TTableFilterItem[]
}

const useFilterPreset = (
    variables : {
        query    ?: TFilterTypeName
        status   ?: TFilterStatus,
        teamId   ?: TID
        userId   ?: TID
        filterId ?: TID | TID[]
    },
    fetchPolicy : WatchQueryFetchPolicy = "cache-only",
    withStatus  : boolean = false,
    skip : boolean = false,
) : IUseVariableLists  => {

    let where;

    if(variables.status === "public"){
        where = {
            column : "QUERY",
            operator : EWhereOperator.EQ,
            value : variables.query as string,
            OR : [ {
                column : "STATUS",
                operator : EWhereOperator.EQ,
                value : variables.status as string,
                AND : [
                    {
                        column : "USER_ID",
                        operator : EWhereOperator.EQ,
                        value : variables.userId as string,
                    },
                    {
                        column : "STATUS",
                        operator : EWhereOperator.NEQ,
                        value : 'default' as string,
                    }
                ]
            } ]
        };
    } else {
        where = {
            column : "QUERY",
            operator : EWhereOperator.EQ,
            value : variables.query as string,
            AND : [ {
                column : "USER_ID",
                operator : EWhereOperator.EQ,
                value : variables.userId as string,
            },
            {
                column : "STATUS",
                operator : EWhereOperator.NEQ,
                value : 'default' as string,
            } ]
        };
    }

    if(variables.filterId){
        const isArray = Array.isArray(variables.filterId);

        where = {
            column : "ID",
            operator : isArray ? EWhereOperator.IN : EWhereOperator.EQ,
            value : !isArray ? variables.filterId as string : variables.filterId as string[],
        };
    }

    const {
        data,
        loading,
        error
    } = useQuery( GET_TABLE_FILTER_REQUESTS, {
        variables : {
            where,
            orderBy : [ { column : "ID", order : "ASC" } ]
        },
        skip,
        fetchPolicy
    });

    const filterPresets = data ?
        data.tableFilterTypeRequests.data.map(( item ) => item) : [];


    if(withStatus)
        return {
            filterPresets,
            loading,
            error
        };

    return { filterPresets };


};

export default useFilterPreset;