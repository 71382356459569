import CardBlock from "./card-block";
import FilterBlock from "./filter-block";
import FiltersBlock from "./filters-block";
import FiltersPresetSaveBlock from "./filters-preset-block";
import HeaderBlock from "./header-block";
import PageHeaderBlock from "./page-header-block";
import SidebarBlock from "./sidebar-block";
import TableHeaderBlock from "./table-header-block";

import "./layout-blocks.scss";


const LayoutBlocks = {
    Header : HeaderBlock,
    PageHeader : PageHeaderBlock,
    TableHeader : TableHeaderBlock,
    Sidebar : SidebarBlock,
    Card : CardBlock,
    Filter : FilterBlock,
    Filters : FiltersBlock,
    FiltersPreset : FiltersPresetSaveBlock
};

export default LayoutBlocks;