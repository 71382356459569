import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";
import { Loader } from "components/request-result";


const LockSvg : React.FC = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <path d="M36.09,19.48h-.42v-6.33c0-6.42-5.22-11.63-11.63-11.63s-11.63,5.22-11.63,11.63v6.33h-.42c-3.12,0-5.67,2.54-5.67,5.67v13.35c0,3.13,2.54,5.67,5.67,5.67h24.1c3.13,0,5.67-2.54,5.67-5.67v-13.35c0-3.13-2.54-5.67-5.67-5.67ZM16.41,13.15c0-4.21,3.42-7.63,7.63-7.63s7.63,3.42,7.63,7.63v6.33h-15.27v-6.33ZM37.76,38.5c0,.92-.75,1.67-1.67,1.67H11.99c-.92,0-1.67-.75-1.67-1.67v-13.35c0-.92.75-1.67,1.67-1.67h24.1c.92,0,1.67.75,1.67,1.67v13.35Z"/>
        <path d="M24.04,27.52c-1.1,0-2,.9-2,2v4.76c0,1.1.9,2,2,2s2-.9,2-2v-4.76c0-1.1-.9-2-2-2Z"/>
    </svg>
);


const LockIcon: FunctionComponent<IIcon> = ({ className, loading }): JSX.Element => {

    if (loading) {
        return <Loader/>;
    }

    return <Icon component={LockSvg} className={className}/>;

};


export default LockIcon;