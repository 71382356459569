import React, { JSX } from "react";

import { Button, Popover } from "antd";

import { Icons } from "components/layout";

import type { IPopoverProps } from "common/types";


interface IPopoverMenuHolderProps extends IPopoverProps{}

const PopoverMenuHolder : React.FC<IPopoverMenuHolderProps> = (
    {
        iconName = 'ThreeDot',
        iconText ,
        btnType = 'text',
        btnClass,
        holderClass = "",
        zIndex = 1030,
        children
    }) : JSX.Element  => {

    const PopoverIcon = Icons[iconName];
    const buttonClass = `${ btnClass || '' } ${ iconText ? 'custom' : '' }`;

    return(
        <Popover
            className = { `popover-menu-holder ${ holderClass }` }
            placement = "bottomRight"
            content   = { children }
            trigger   = { [ "click" ] }
            align     = {{ offset : [ 3, 2 ] }}
            zIndex    = { zIndex }
        >
            <Button
                type      = { btnType }
                className = { `popover-btn ${buttonClass}` }
            >
                <PopoverIcon />
                { iconText ? ` ${ iconText }` : null }
            </Button>
        </Popover>
    );
};

export default PopoverMenuHolder;