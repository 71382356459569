import FiltersDrawer from "./Filters-drawer";
import FiltersPresetModal from "./Filters-preset-modal";

import "./modals.scss";


const LayoutModals = {
    Filters : FiltersDrawer,
    FilterPreset : FiltersPresetModal
};

export default LayoutModals;