import React, { JSX }  from "react";

import { useOutletContext } from "react-router-dom";

import CheckList from "components/check-list";

import type { TCheckListGroups } from "components/check-list/check-list-types";


const { Participants } = CheckList.Forms;

const CheckListParticipantsSubPage : React.FC = () : JSX.Element => {

    const { ee, checkLists } = useOutletContext<{ee : any, checkLists: TCheckListGroups  }>();

    const { fieldGroups } = checkLists.Participants;

    return(
        <div className="page-content">
            <Participants.Organizer
                eeId      = { ee.id }
                listGroup = "Organizer"
                formData  = { fieldGroups.Organizer }
            />
            <Participants.Subcontractors
                eeId      = { ee.id }
                listGroup = "Subcontractors"
                formData  = { fieldGroups.Subcontractors }
            />
            <Participants.MPTeam
                eeId      = { ee.id }
                listGroup = "TeamOnSite"
                formData  = { fieldGroups.TeamOnSite }
            />
        </div>
    );
};

export default CheckListParticipantsSubPage;