import { gql, TypedDocumentNode } from '@apollo/client';

import type { IQueryVariables } from "../type/common-gql-types";
import type { TTeamsCursorData } from "../type/user-gql-types";


export const GET_TEAMS: TypedDocumentNode<{ teamsCursor: TTeamsCursorData }, IQueryVariables > = gql`
    query GetTeams (
        $text    : String
        $where   : QueryTeamsCursorWhereWhereConditions
        $orderBy : [QueryTeamsCursorOrderByOrderByClause!]
        $after   : String
    ){
        teamsCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 50
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    title
                }
            }
        }
    }
`;
