import React, { JSX } from "react";

import { useOutletContext } from "react-router-dom";

import CheckList from "components/check-list";

import type { TCheckListGroups } from "components/check-list/check-list-types";


const { ExpoDates } = CheckList.Forms;

const CheckListExpoDatesSubPage : React.FC = () : JSX.Element => {

    const { ee, checkLists } = useOutletContext<{ee : any, checkLists: TCheckListGroups  }>();

    const { fieldGroups } = checkLists.ExpoDates;

    return(
        <div className="page-content">
            <ExpoDates.Official
                eeId      = { ee.id }
                listGroup = "OfficialDates"
                formData  = { fieldGroups.OfficialDates }
            />
            <ExpoDates.Additional
                eeId      = { ee.id }
                listGroup = "AdditionalDates"
                formData  = { fieldGroups.AdditionalDates }
            />
            <ExpoDates.Internal
                eeId      = { ee.id }
                listGroup = "InternalDates"
                formData  = { fieldGroups.InternalDates }
            />
        </div>
    );
};

export default CheckListExpoDatesSubPage;