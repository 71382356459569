import { TFilterPresetTypes, TFilterTypeName } from "../types";


const parseFilterPresetData = (key : TFilterTypeName, presets :  TFilterPresetTypes) => {
    const presetData = presets[key]?.data;

    if(presetData){
        try {
            const data = JSON.parse(presetData);
            if(data){
                return data;
            }

            return {};
        } catch (e) {
            return {};
        }
    }

    return {};
};


const filterDataHelper = {
    parsePreset : parseFilterPresetData
};

export default filterDataHelper;