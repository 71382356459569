import FiltersPresetList from "./filters-preset-list";
import MessageList from "./message-list";

import "./layout-lists.scss";


const LayoutList = {
    Messages : MessageList,
    FiltersPreset : FiltersPresetList
};

export default LayoutList;
