import { useEffect } from "react";

import { useMutation } from "@apollo/client";

import { TABLE_FILTER_CREATE_DEFAULT } from "graphql/mutation/common-m.gql";

import { useNotification } from "./index";
import { reactiveVarHelper } from "common/helpers";

import { EFilterPresetGroups, type TFilterPresetTypes, TFilterTypeName } from "common/types";
import type { TMetaItem, TTableFilterDefaultPayload } from "graphql/type/common-gql-types";


type TUseCheckDefaultFilterPresetsProps = {
    metaDefaultFilters :  TMetaItem[]
    loading ?: boolean
};


const useCheckDefaultFilterPresets = ({ metaDefaultFilters, loading } : TUseCheckDefaultFilterPresetsProps) => {

    const rVar = reactiveVarHelper('filter'),
          rVarFilters = rVar.get('all'),
          { nSuccess, nError } = useNotification();

    const [ createDefaultFilters, { loading : CRLoading, called } ] = useMutation(  TABLE_FILTER_CREATE_DEFAULT,
        {
            update(_, { data  }) {

                const {
                    tableFilterCreateDefault : {
                        label,
                        message,
                        tableFilters,
                        userMetas

                    }
                } = data as { tableFilterCreateDefault : TTableFilterDefaultPayload };

                const filterPresets: TFilterPresetTypes = {};

                tableFilters.forEach((filter) => {
                    filterPresets[filter.query] = filter;
                });

                rVar.set({
                    metaFilters : [
                        ...rVarFilters.metaFilters,
                        ...userMetas,
                    ],
                    defaultPresets : {
                        ...rVarFilters.defaultPresets,
                        ...filterPresets

                    },
                    currentPresets : {
                        ...rVarFilters.currentPresets,
                        ...filterPresets
                    }

                });

                nSuccess(label, message);

            },
            onError(error) {
                console.error(error);
            }
        }
    );

    const unsettedFilters: TFilterTypeName[] = [];

    Object.keys(EFilterPresetGroups).forEach( (group) => {
            const filterTypeName = EFilterPresetGroups[group as keyof typeof EFilterPresetGroups];

            const filter = metaDefaultFilters.find(
                ( defaultFilter ) => defaultFilter.meta_key === filterTypeName
            );

            if (!filter){
                unsettedFilters.push(filterTypeName as TFilterTypeName);
            }
        }
    );

    useEffect(() => {
        if(unsettedFilters.length > 0 && !loading && !CRLoading && !called){
            //console.log(unsettedFilters);

            createDefaultFilters({
                variables : {
                    typeList : unsettedFilters
                }
            }).catch(nError);
        }
    }, [ unsettedFilters ]);

    return CRLoading;
};

export default useCheckDefaultFilterPresets;