import React from "react";

import { useQuery } from "@apollo/client";
import { Radio } from "antd";

import { GET_EQUIPMENTS } from "graphql/query/equipment-q.gql";

import { reactiveVarHelper } from "common/helpers";
import { whereConditionBuilder } from "common/utils";
import { Loader } from "components/request-result";

import type { TID } from "graphql/type/common-gql-types";
import type { TEquipmentItem } from "graphql/type/equipment-gql-types";


export type TStandTypeListProps = {
    eeId          ?: TID
    groupId       ?: TID
    etId           : TID
    orgId          : TID
    setEquipment   : (props : TEquipmentItem) => void
    equipment     ?: TEquipmentItem
    text          ?: string | undefined
};

const EquipmentRadioList : React.FC<TStandTypeListProps> = ({
        etId,
        orgId,
        groupId,
        setEquipment,
        equipment,
        text
    }) => {

    const me = reactiveVarHelper().get('me');

    const { data, loading } = useQuery( GET_EQUIPMENTS, {
        variables : {
            groupId : groupId || undefined,
            text : text && text.length ? text : undefined,
            where : whereConditionBuilder({
                lang : {
                    column : "LANG",
                    operator : "EQ",
                    value : me.lang_api
                },
            }),
            whereExhibitionType : whereConditionBuilder({
                exhibitionTypeId : !etId ? {} : {
                    column : "ET_ID",
                    operator : "EQ",
                    value : etId
                },
                organizerId : !orgId ? {} : {
                    column : "ORGANIZER_ID",
                    operator : "EQ",
                    value : orgId
                },
            }),
            orderBy : [ { column : "TITLE", order : "ASC" } ]
        },
        fetchPolicy : "cache-first",
    });

    const {
        equipmentsCursor : {
            edges : equipments = []
        } = {}
    } = data || {};

    return(
        <div className={`radio-select-wrap ${loading ? "loading" : ""}`}>
            {loading ?
                <Loader/> :
                <Radio.Group
                    onChange={(e) => {

                        const { node } = equipments.find(
                            ({ node }) => Number(node.group_id) === Number(e.target.value)!
                        )!;

                        setEquipment({ ...node });
                    }}
                    value={ equipment ? equipment.group_id : undefined }
                >
                    {equipments.map((
                        { node : { id, title, navision_number, group_id, getImages } }
                    ) => {

                        const [ img ] = getImages;

                        return (
                            <Radio key={id} value={group_id}>
                                <div className="img-wrap">
                                    <img src={img.sizes['200x200']} alt={img.server_name}/>
                                </div>
                                <div className="info">
                                    <span>{title}</span>
                                    <span className="label">{navision_number} </span>
                                </div>
                                <div className="action"></div>
                            </Radio>
                        );
                    })}

                </Radio.Group>
            }
            { !loading && !equipments.length &&
                <div className="no-data">
                    <p className="label">No equipments with these parameters</p>
                </div>
            }
        </div>
    );
};

export default EquipmentRadioList;