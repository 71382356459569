import { useMutation } from "@apollo/client";

import { TABLE_FILTER_CREATE, TABLE_FILTER_UPDATE } from "graphql/mutation/common-m.gql";

import { useNotification } from "components/use-hook";

import type { TTableFilterInput, TTableFilterItem, TTableFilterItemPayload } from "graphql/type/common-gql-types";


interface IUseFilterPresetMutationProps {
    isCreate ?: boolean
    onCreate ?: (tableFilter : TTableFilterItem) => void
    onUpdate ?: (tableFilter : TTableFilterItem) => void
}

const useFilterPresetMutationHook = (
    {
        isCreate = false,
        onCreate = () => {},
        onUpdate = () => {},
    } : IUseFilterPresetMutationProps) => {

    const { nSuccess, nError } = useNotification();

    const [ filterPresetCreate, { loading : createLoading } ] = useMutation( TABLE_FILTER_CREATE,
        {
            update(_, { data  }) {

                const {
                    tableFilterCreate : {
                        label,
                        message,
                        tableFilter,
                    }
                } = data as { tableFilterCreate : TTableFilterItemPayload };

                onCreate(tableFilter);

                nSuccess(label, message);

            },
            onError(error) {
                console.error(error);
            }
        }
    );

    const [ filterPresetUpdate, { loading : updateLoading } ] = useMutation( TABLE_FILTER_UPDATE,
        {
            update(_, { data  }) {

                const {
                    tableFilterUpdate : {
                        label,
                        message,
                        tableFilter,
                    }
                } = data as { tableFilterUpdate : TTableFilterItemPayload };

                onUpdate(tableFilter);

                nSuccess(label, message);

            },
            onError(error) {
                console.error(error);
            }
        }
    );

    return {
        loading : isCreate ? createLoading : updateLoading,
        tableFilterAction : ({
             variables
        } : { variables : { input: TTableFilterInput} }) => {

            if(isCreate && variables && !variables?.input.id){
                filterPresetCreate({
                    variables : {
                        input : {
                            ...variables.input,
                            type : "request"
                        }
                    }
                }).catch(nError);
            }

            if(!isCreate && variables && variables.input.id){
                filterPresetUpdate({
                    variables : {
                        input : {
                            ...variables.input,
                            type : "request"
                        }
                    }
                }).catch(nError);
            }
        }
    };
};

export default useFilterPresetMutationHook;