import React, { JSX, useEffect } from 'react';

import { useQuery } from "@apollo/client";

import { GET_PRIVATE_PARAMS } from "graphql/query/local-store.gql";

import { Blocks } from "components/layout";
import { AppRoutes } from "components/routes";
import { usePrivateAppInit, useTranslation } from "components/use-hook";

import './app.scss';


export let translations = useTranslation();

const PrivateApp : React.FC = () : JSX.Element | null =>  {

    const loading = usePrivateAppInit(),
          { data : { privateParams } } = useQuery( GET_PRIVATE_PARAMS );

    useEffect(() => {
        if(!loading){
            translations = useTranslation();
        }
    }, [ loading ]);

    if(loading){
        return null;
    }

    return (
        <div className={ `app ${ privateParams.appBlockClass.join(" ") }` }>
            <Blocks.Header />
            <main className={ `${ privateParams.mainBlockClass.join(" ") }` }>
                <AppRoutes />
            </main>
        </div>
    );
};

export default PrivateApp;
