import useFilterSaveHook from "./use-filter-save-hook";

import type { TFilterTypeName } from "common/types";


type TUseFilterResetHookProps = {
    filterTypeName : TFilterTypeName
};

const useFilterResetHook = ({ filterTypeName } : TUseFilterResetHookProps) => {

    const { loading : loadingFilter, filterSave } = useFilterSaveHook({ filterTypeName });

    return {
        loading : loadingFilter,
        filterReset : () => {
            filterSave(JSON.stringify({ text : '' }));
        }
    };

};

export default useFilterResetHook;