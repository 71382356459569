import React, { JSX, useEffect, useState } from "react";

import { Input } from "antd";

import SearchIcon from "../icons/Search-icon";
import { localizeByKey } from "components/service/Localize";
import { useDebounce } from "components/use-hook";


type TSearchFieldProps<FiltersType> = {
    filters: FiltersType,
    setFilters : React.Dispatch<React.SetStateAction<FiltersType>>
};

const SearchField = <FiltersType, >(
    {
        filters,
        setFilters
    } : TSearchFieldProps<FiltersType>) : JSX.Element => {

    const [ text, setText ] = useState("" );

    const debouncedFilter = useDebounce(
        (newText) => {
                setFilters({
                    ...filters, text : newText,
                });
            }, 500
        );

    useEffect(() => {
        debouncedFilter(text);
    }, [ text ]);

    return(
        <div className="search-field">
            <Input
                placeholder = { localizeByKey('GLOBAL.Input_Placeholder_TypeToSearch') }
                prefix      = { <SearchIcon /> }
                className   = "search-input"
                onChange    = { (e) =>  setText(e.target.value) }
                value       = { text }
                name        = "search-in-table"
            />
        </div>
    );
};

export default SearchField;