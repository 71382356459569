import React, { JSX } from "react";

import { Button } from "antd";

import LayoutHooks from "../../hooks";
import Icons from "../../icons";
import Modals from "../../modals";
import Popover from "../popover-menu";
import Localize from "components/service/Localize";

import type { ButtonType } from "antd/es/button/buttonHelpers";
import type { IFiltersBlocksProps } from "common/types";


interface IFilterPresetMenuProps<FiltersType> extends IFiltersBlocksProps<FiltersType>{
    isSave    : boolean
    btnType  ?: ButtonType
    btnClass ?: string
}

const FilterPresetMenu  = <FiltersType, >(
    {
        isSave,
        btnType = 'default',
        btnClass = 'inverted',
        ...props
    } : IFilterPresetMenuProps<FiltersType>) : JSX.Element => {

    const { loading : resetLoading, filterReset } = LayoutHooks.mutation.filterReset({
        filterTypeName : props.name
    });

    return (
        <Popover
            iconName = { 'FilterPreset' }
            btnClass = { btnClass }
            btnType  = { btnType }
        >
            <Modals.FilterPreset
                modalType = "Use"
                { ...props }
            />
            { isSave ?
                <Modals.FilterPreset
                    modalType = "Save"
                    { ...props }
                /> :
                <Button
                    type     = 'text'
                    disabled ={ resetLoading }
                    onClick  = {
                        () => {
                            filterReset();
                            props.state.setFilters( { text : '' } as FiltersType );
                        }
                    }
                >
                    <Icons.Delete loading={ resetLoading } />
                    <Localize wrap>GLOBAL.Button_Label_ResetFilter</Localize>
                </Button>
            }
        </Popover>
    );
};

export default FilterPresetMenu;