import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";


const MovableSvg : React.FC = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <path d="M19.39,27.57c-1.41,0-2.56,1.15-2.56,2.56s1.15,2.56,2.56,2.56,2.56-1.15,2.56-2.56-1.15-2.56-2.56-2.56Z"/>
        <path d="M19.39,15.17c-1.41,0-2.56,1.15-2.56,2.56s1.15,2.56,2.56,2.56,2.56-1.15,2.56-2.56-1.15-2.56-2.56-2.56Z"/>
        <path d="M19.39,39.97c-1.41,0-2.56,1.15-2.56,2.56s1.15,2.56,2.56,2.56,2.56-1.15,2.56-2.56-1.15-2.56-2.56-2.56Z"/>
        <path d="M19.39,2.78c-1.41,0-2.56,1.15-2.56,2.56s1.15,2.56,2.56,2.56,2.56-1.15,2.56-2.56-1.15-2.56-2.56-2.56Z"/>
        <path d="M28.61,27.57c-1.41,0-2.56,1.15-2.56,2.56s1.15,2.56,2.56,2.56,2.56-1.15,2.56-2.56-1.15-2.56-2.56-2.56Z"/>
        <path d="M28.61,15.17c-1.41,0-2.56,1.15-2.56,2.56s1.15,2.56,2.56,2.56,2.56-1.15,2.56-2.56-1.15-2.56-2.56-2.56Z"/>
        <path d="M28.61,39.97c-1.41,0-2.56,1.15-2.56,2.56s1.15,2.56,2.56,2.56,2.56-1.15,2.56-2.56-1.15-2.56-2.56-2.56Z"/>
        <path d="M28.61,7.9c1.41,0,2.56-1.15,2.56-2.56s-1.15-2.56-2.56-2.56-2.56,1.15-2.56,2.56,1.15,2.56,2.56,2.56Z"/>
    </svg>
);


const MovableIcon: FunctionComponent<IIcon> = ({ className }): JSX.Element => {
    return <Icon component={ MovableSvg } className={ className }/>;

};


export default MovableIcon;