import React, { JSX } from "react";

import { Button } from "antd";

import LayoutHooks from "../hooks";
import Icons from "../icons";
import Localize from "components/service/Localize";

import type { TFilterStatus, TID, TTableFilterInput } from "graphql/type/common-gql-types";


interface IFilterPresetChangeStatusButtonProps{
    id     : TID
    name   : string
    status : TFilterStatus
}

const FilterPresetChangeStatusButton : React.FC<IFilterPresetChangeStatusButtonProps> = ({ id, status }) : JSX.Element => {

    const { loading, tableFilterAction } = LayoutHooks.mutation.filterPreset({ isCreate : false });

    return (
        <Button
            type    = 'text'
            onClick = {
                () => tableFilterAction({
                    variables : {
                        input : {
                            id,
                            status : status === 'private' ? "public" : 'private'
                        } as TTableFilterInput
                    }
                })
            }
        >
            <Icons.Eye
                loading={ loading }
                type={ status === 'private' ? 'open' : 'closed' }
            />
            <Localize wrap>
                { `GLOBAL.Action_Menu_Make_${status === 'private' ? "public" : 'private'}` }
            </Localize>
        </Button>
    );
};

export default FilterPresetChangeStatusButton;