import { gql, TypedDocumentNode } from "@apollo/client";

import {
    TID,
    TMetaModel,
    TMetaModelItemPayload,
    TModelMetaInput,
    TPayload, TTableFilterDefaultPayload,
    TTableFilterInput,
    TTableFilterItemPayload
} from "../type/common-gql-types";


export const ATTACHMENT_DELETE = gql`
    mutation attachmentDelete($id: ID!) {
        attachmentDelete(id: $id){
            label
            message
        }
    }
`;

export const TABLE_FILTER_CREATE_DEFAULT : TypedDocumentNode<
    { tableFilterCreateDefault : TTableFilterDefaultPayload },
    { typeList : string[] }
> = gql`
    mutation tableFilterCreateDefault($typeList: [String!]!) {
        tableFilterCreateDefault(typeList: $typeList){
            label
            message
            tableFilters {
                id
                title
                status
                type
                query
                data
                team_id
                user_id
                relUser {
                    id
                    name
                    surname
                }
                relTeam {
                    id
                    title
                }
                created_at
            }
            userMetas {
                id
                user_id
                meta_group
                meta_key
                meta_type
                meta_value
            }
        }
    }
`;

export const TABLE_FILTER_CREATE : TypedDocumentNode<
    { tableFilterCreate : TTableFilterItemPayload },
    { input : TTableFilterInput }
> = gql`
    mutation tableFilterCreate($input: TableFilterFieldsInput!) {
        tableFilterCreate(input: $input){
            label
            message
            tableFilter {
                id
                title
                status
                type
                query
                data
                team_id
                user_id
                relUser {
                    id
                    name
                    surname
                }
                relTeam {
                    id
                    title
                }
                created_at
            }
        }
    }
`;


export const TABLE_FILTER_UPDATE : TypedDocumentNode<
    { tableFilterUpdate : TTableFilterItemPayload },
    { input : Partial<TTableFilterInput> }
> = gql`
    mutation tableFilterUpdate($input: TableFilterFieldsInput!) {
        tableFilterUpdate(input: $input){
            label
            message
            tableFilter {
                id
                title
                status
                type
                query
                data
                team_id
                user_id
                relUser {
                    id
                    name
                    surname
                }
                relTeam {
                    id
                    title
                }
                created_at
            }
        }
    }
`;

export const TABLE_FILTER_DELETE : TypedDocumentNode<
    { tableFilterDelete : TPayload },
    { id : TID }
> = gql`
    mutation tableFilterDelete( $id: ID! ) {
        tableFilterDelete( id: $id ){
            label
            message
        }
    }
`;

export const META_MODEL_UPDATE : TypedDocumentNode<
    { modelMetaSet : TMetaModelItemPayload },
    { input : Partial<TModelMetaInput> }
> = gql`
    mutation modelMetaSet($input: ModelMetaInput!) {
        modelMetaSet(input: $input){
            label
            message
            model {
                ...on UserMeta {
                    id
                    user_id
                    meta_group
                    meta_key
                    meta_type
                    meta_value
                }
            }
        }
    }
`;

export const META_MODEL_DELETE : TypedDocumentNode<
    { modelMetaDelete : TPayload },
    {
        id : TID
        model : TMetaModel
    }
> = gql`
    mutation modelMetaDelete( 
        $id    : ID!
        $model : modelMetaNameEnum!
    ) {
        modelMetaDelete( 
            id    : $id 
            model : $model 
        ){
            label
            message
        }
    }
`;