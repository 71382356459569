import React, { JSX } from "react";

import { Input } from "antd";

import EquipmentFields from "../fields";
import EquipmentHelpers from "../helpers";
import { reactiveVarHelper } from "common/helpers";
import Exhibition from "components/exhibition";
import { Blocks } from "components/layout";

import { EEquipmentFilterFieldTypes, TEquipmentFilters } from "components/equipment/equipment.types";
import type { IFilterReactiveVar } from "graphql/reactive-variables";
import type { TID } from "graphql/type/common-gql-types";


type TEquipmentListFilterProps = {
    eeId        : TID
    filters     : TEquipmentFilters,
    setFilters  : React.Dispatch<React.SetStateAction<TEquipmentFilters>>
    callBack   ?: () => void
};

const EquipmentListFilterForm : React.FC<TEquipmentListFilterProps> = (
    {
        eeId,
        filters,
        setFilters,
        callBack = () => {},
    }) : JSX.Element => {

    const rVarFilter = reactiveVarHelper('filter');
    const { EquipmentList : preset } = rVarFilter.get('currentPresets') as IFilterReactiveVar["currentPresets"];

    return (
        <Blocks.Filter.Form
            filterFields     = { EquipmentHelpers.filter.parse(filters) }
            fieldTypes       = { EEquipmentFilterFieldTypes }
            defaultFiledType = { EEquipmentFilterFieldTypes.NONE }
            translationKey   = { 'EQUIPMENT.Select_Option_Filter' }
            filterTypeName   = { "EquipmentList" }
            onReset={
                () => {
                    setFilters({ text : '' });
                    callBack();
                }
            }
            onChange = {
                (fields) => {
                    localStorage.setItem(
                        "tmpFilterFields",
                        JSON.stringify({
                            text : filters.text,
                            ...EquipmentHelpers.filter.build(fields)
                        })
                    );
                }
            }
            onApply={
                (fields, saveFilter) => {
                    const newFilterData = JSON.stringify({
                        text : filters.text,
                        ...EquipmentHelpers.filter.build(fields)
                    });

                    if(preset?.data !== newFilterData){
                        saveFilter( newFilterData );
                    }

                    setFilters({
                        text : filters.text,
                        ...EquipmentHelpers.filter.build(fields)
                    });
                    callBack();
                }
            }
        >
            {
                ({
                     fieldSettings,
                     filteredTypes,
                     fieldItem : { type, value/*,  label */ }
                 }) => {

                    switch (type){
                        case EEquipmentFilterFieldTypes.CAD_NUMBER:
                        case EEquipmentFilterFieldTypes.BLOCK:
                        case EEquipmentFilterFieldTypes.STAND_NUMBER:
                        case EEquipmentFilterFieldTypes.COMPANY_NAME:
                            return (
                                <Blocks.Filter.Item<EEquipmentFilterFieldTypes> { ...fieldSettings }>
                                    <Input />
                                </Blocks.Filter.Item>
                            );
                        case EEquipmentFilterFieldTypes.HALL:
                            return (
                                <Blocks.Filter.Item<EEquipmentFilterFieldTypes> { ...fieldSettings }>
                                    <Exhibition.Fields.HallSelect
                                        eeId  = { Number(eeId) }
                                    />
                                </Blocks.Filter.Item>
                            );
                        case EEquipmentFilterFieldTypes.NONE:
                        default:
                            return (
                                <Blocks.Filter.Item<EEquipmentFilterFieldTypes>
                                    { ...fieldSettings }
                                    noValue = { true }
                                >
                                    <EquipmentFields.FilterTypeSelect
                                        filteredTypes = { filteredTypes as EEquipmentFilterFieldTypes[]}
                                        defaultValue  = {
                                            value && value.length ?
                                                value as EEquipmentFilterFieldTypes :
                                                type as EEquipmentFilterFieldTypes
                                        }
                                    />
                                </Blocks.Filter.Item>
                            );
                    }
                }}
        </Blocks.Filter.Form>
    );
};

export default EquipmentListFilterForm;