import React, { JSX, useState } from "react";

import { filterDataHelper, reactiveVarHelper } from "common/helpers";
import Exhibition from "components/exhibition";
import { Blocks } from "components/layout";
import { LinkWithIcon } from "components/service";
import Localize from "components/service/Localize";

import type { TExhibitionFilters } from "components/exhibition/exhibition-types";
import type { IFilterReactiveVar } from "graphql/reactive-variables";
 

const ExhibitionsPage : React.FC = () : JSX.Element | null => {

    const rVarFilter = reactiveVarHelper('filter'),
          { currentPresets } = rVarFilter.get('all') as IFilterReactiveVar,
          parsedPresets = filterDataHelper.parsePreset('ExhibitionList', currentPresets);

    const [ filters, setFilters ] = useState<TExhibitionFilters>({
        text : "",
        ...parsedPresets,
    });

    return(
        <div className="page exhibitions-page">
            <Blocks.PageHeader
                title       = { <Localize>EXHIBITION.List_PageTitle_Exhibitions</Localize> }
                hideEEBlock = { true }
            />
            <div className="page-content-wrap">
                <Blocks.TableHeader
                    filters = {
                        <Blocks.Filters<TExhibitionFilters>
                            state = {{ filters, setFilters }}
                            name  = { "ExhibitionList" }
                        >
                            <Exhibition.Forms.ListFilter
                                filters      = { filters }
                                setFilters   = { setFilters }
                            />
                        </Blocks.Filters>
                    }
                    actions = {<>
                        <LinkWithIcon route={ "EXHIBITION_ITEM_CREATE" } >
                            <Localize>
                                EXHIBITION.Button_Label_AddExhibition
                            </Localize>
                        </LinkWithIcon>
                    </>}
                />
                <Exhibition.Tables.All
                    filters={ JSON.parse(JSON.stringify(filters)) }
                />
            </div>
        </div>

    );
};

export default ExhibitionsPage;