import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";
import { Loader } from "components/request-result";


const SettingPresetSvg : React.FC = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <path d="M5.09,12.82h5.98v1.29c0,1.1.9,2,2,2s2-.9,2-2v-1.29h27.46c1.1,0,2-.9,2-2s-.9-2-2-2H15.07v-1.29c0-1.1-.9-2-2-2s-2,.9-2,2v1.29h-5.98c-1.1,0-2,.9-2,2s.9,2,2,2Z"/>
        <path d="M42.53,22h-6.56v-1.29c0-1.1-.9-2-2-2s-2,.9-2,2v1.29H5.09c-1.1,0-2,.9-2,2s.9,2,2,2h26.87v1.29c0,1.1.9,2,2,2s2-.9,2-2v-1.29h6.56c1.1,0,2-.9,2-2s-.9-2-2-2Z"/>
        <path d="M42.53,34.93H15.07v-1.29c0-1.1-.9-2-2-2s-2,.9-2,2v1.29h-5.98c-1.1,0-2,.9-2,2s.9,2,2,2h5.98v1.29c0,1.1.9,2,2,2s2-.9,2-2v-1.29h27.46c1.1,0,2-.9,2-2s-.9-2-2-2Z"/>
    </svg>
);


const SettingPresetIcon: FunctionComponent<IIcon> = ({ className, loading }): JSX.Element => {

    if (loading) {
        return <Loader/>;
    }

    return <Icon component={ SettingPresetSvg } className={className}/>;

};


export default SettingPresetIcon;