import { useMutation } from "@apollo/client";

import { META_MODEL_UPDATE } from "graphql/mutation/common-m.gql";

import { useNotification } from "components/use-hook";

import type {
    TMetaItem,
    TMetaModelItemPayload,
    TModelMetaInput,
} from "graphql/type/common-gql-types";

 
interface IUseFilterPresetMutationProps {
    onUpdate ?: (meta : TMetaItem) => void
}

const useMetaSetHook = (
    {
        onUpdate = () => {},
    } : IUseFilterPresetMutationProps) => {

    const { nSuccess, nError } = useNotification();

    const [ modelMetaSet, { loading  } ] = useMutation( META_MODEL_UPDATE,
        {
            update(_, { data  }) {

                const {
                    modelMetaSet : {
                        label,
                        message,
                        model
                    }
                } = data as { modelMetaSet : TMetaModelItemPayload };

                onUpdate(model);

                nSuccess(label, message);

            },
            onError(error) {
                console.error(error);
            }
        }
    );


    return {
        loading,
        metaSet : ({ variables } : { variables : { input: TModelMetaInput} }) => {

            if(variables){
                modelMetaSet({
                    variables : { input : variables.input }
                }).catch(nError);
            }
        }
    };
};

export default useMetaSetHook;