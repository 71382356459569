import React from "react";

import { generatePath, Link } from "react-router-dom";

import { Icons } from "components/layout";
import { ROUTES } from "components/routes";
import { TagStandard } from "components/service";
import Localize from "components/service/Localize";
import Task from "components/task";

import type { TNode } from "graphql/type/common-gql-types";
import type { TTask } from "graphql/type/task-gql-types";
import type { FixedType } from "rc-table/lib/interface";


const columns  = [
    {
        title :
            <>
                <Localize wrap tag="div" className="table-section-label">
                    TASK.TableColumn_Group_StandInfo
                </Localize>
                <Localize br={<br />}>
                    GRAPHICS.TableColumn_Header_ClientName
                </Localize>
            </>
        ,
        dataIndex : "standCompanyName",
        columnIndex : "TITLE",
        width : 240,
        fixed : 'left' as FixedType,
        className : "general-info"
        //sorter: true,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_ProjectManager
            </Localize>
        ,
        dataIndex : "standProjectManager",
        width : 140,
        className : "general-info"
    },
    {
        title :
            <>
                <Localize>
                    STAND.TableColumn_Header_CADNumber
                </Localize>
            </>,
        dataIndex : "cadNumber",
        columnIndex : "CAD_NUMBER",
        className : "general-info",
        width : 100,
    },
    {
        title :
            <>
                <Localize wrap tag="div" className="table-section-label">
                    TASK.TableColumn_Group_CurrentTaskInfo
                </Localize>
                <Localize>
                        STAND.TableColumn_Header_TaskStatus
                </Localize>
            </>,
        dataIndex : "status",
        columnIndex : "STATUS",
        className : "task-info",
        width : 150
    },
    {
        title :
                <Localize>
                    STAND.TableColumn_Header_Importance
                </Localize>,
        dataIndex : "importance",
        columnIndex : "IMPORTANCE",
        className : "task-info",
        width : 150,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_TaskType
            </Localize>,
        dataIndex : "type",
        columnIndex : "TYPE",
        className : "task-info",
        width : 150
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_AssignedTo
            </Localize>,
        dataIndex : "assigned_to_id",
        columnIndex : "ASSIGNED_TO_ID",
        className : "task-info",
        width : 180,
    },
    {
        title :
            <Localize>
                TASK.TableColumn_Header_Details
            </Localize>,
        dataIndex : "task",
        columnIndex : "TASK",
        className : "task-info",
        width : 350
    },
    {
        title :
            <>
                <Localize wrap tag="div" className="table-section-label">
                    STAND.TableColumn_Group_CADInfo
                </Localize>
                <Localize>
                    STAND.TableColumn_Header_CADStatus
                </Localize>
            </>,
        dataIndex : "cadStatus",
        columnIndex : "CAD_STATUS",
        className : "cad-info",
        width : 180,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_CadChanges
            </Localize>,
        dataIndex : "cadChanges",
        columnIndex : "CAD_CHANGES",
        className : "cad-info",
        width : 80,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_CadVersion
            </Localize>,
        dataIndex : "cadVersion",
        columnIndex : "CAD_VERSION",
        className : "cad-info",
        width : 150,
    },
    // {
    //     title :
    //         <Localize>
    //             STAND.TableColumn_Header_Comments
    //         </Localize>,
    //     columnIndex : "TOTAL_COMMENT",
    //     dataIndex : "totalComments",
    //     className : "general-info",
    //     width : 90,
    // },
];


const dataMap = ( tasks : TNode<TTask>[] ) => {

    return tasks.map( ({ node  }) => {

        const {
            id,
            ee_id,
            title,
            description,
            importance,
            assigned_to_id,
            status,
            stand_id,
            type,
            relStand,
            relAssignedTo,
            relCad,
            total_comment
        } = node;

        return{
            key : id,
            standCompanyName :
                <>
                    <Link className="item-link"
                          to={
                              generatePath(
                                  ROUTES.STAND_ITEM_FULL,
                                  {
                                      eeId : Number(ee_id),
                                      standId : Number(stand_id)
                                  }
                              )
                          }
                          state       = {{ fromPage : 'task-list' }}
                    >
                        { relStand.company_name }
                    </Link>
                    <p className="stand-info">
                        { relStand.hall_title } / { relStand.stand_number } / { relStand.block }
                    </p>
                </>,
            standHall : relStand?.hall_title || <>—</>,
            standBlock : relStand?.block || <>—</>,
            standNumber : relStand?.stand_number || <>—</>,
            cadNumber : relCad ? relCad.cad_number : <>—</>,
            status : status ?
                <TagStandard className={Task.Helpers.status(status)}>
                    <Localize>{`VARIABLE_ITEM.${ status }`}</Localize>
                </TagStandard>
                : <>—</>,
            importance : importance ?
                <Task.Helpers.Importance name={ importance } onlyIcon={false} /> : <>—</>,
            type : type ?
                <Localize wrap tag="p" className="type text">
                    {`VARIABLE_ITEM.${ type }`}
                </Localize>
                : <>—</>,
            assigned_to_id : assigned_to_id && relAssignedTo  ?
                <>{relAssignedTo.name} {relAssignedTo.surname}</> : <>—</>,
            task : node ?
                <>
                    <p>
                        <b>{ title }</b>
                    </p>
                    <p>
                        <span>{ description?.length > 100 ? `${description.substring(0,100)}...` : description }</span>
                        <Link className="table-link" to={
                            generatePath(
                                ROUTES.STAND_ITEM_TASK_FULL,
                                {
                                    taskId : Number(id),
                                    eeId : Number(ee_id),
                                    standId : Number(stand_id)
                                }
                            )
                        }>
                            <Localize>
                                NAVIGATION.Button_Text_View
                            </Localize>
                        </Link>
                    </p>

                </>
                : <>—</>,
            cadStatus : relCad ?
                <TagStandard className={Task.Helpers.status(relCad.status)}>
                    <Localize>{`VARIABLE_ITEM.${ relCad.status }`}</Localize>
                </TagStandard> :  <>—</>,
            cadChanges : relCad ? relCad.correction :  <>—</>,
            cadVersion : relCad ?
                <div className="cad-version>">
                    <Localize versionNumber={`${relCad.version}.${relCad.correction}`}>
                        GLOBAL.Label_Text_Version
                    </Localize> <br/>
                    {relCad.updated_at}
                </div> :
                <>—</>,
            standProjectManager : relStand?.relProjectManager ? `${relStand.relProjectManager.name} ${relStand.relProjectManager.surname}` : <>—</>,
            standAutocadEngineer : relStand?.relAutocadEngineer  ? `${relStand?.relAutocadEngineer.name} ${relStand?.relAutocadEngineer.surname}` : <>—</>,
            totalComments :
                <>
                    <Icons.Comment/>
                    {total_comment || 0}
                </>,
        };

    });
};

const TaskTableHelper = {
    columns,
    totalColumnWidth : () : number => {
        return columns.reduce( (total, item) => total + item.width + 3, 0) + 20;
    },
    dataMap,
};

export default TaskTableHelper;