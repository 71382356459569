import equipmentFilterHelper from "./equipment-filter-helper";
import equipmentOrderTableHelper from "./equipment-order-table-helper";


const EquipmentHelpers = {
    orderTable : equipmentOrderTableHelper,
    filter : equipmentFilterHelper
};

export default EquipmentHelpers;
