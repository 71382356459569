import React, { JSX } from 'react';

import { useQuery } from "@apollo/client";
import { Table } from 'antd';

import { GET_STAND_EQUIPMENT_ORDER_TOTAL } from "graphql/query/equipment-q.gql";
import { GET_STANDS_FOR_EQUIPMENT } from "graphql/query/stand-q.gql";

import StandHelpers from "../helpers";
import { reactiveVarHelper } from "common/helpers";
import { whereConditionBuilder } from "common/utils";
import { Loader } from "components/request-result";

import type { TStandFilters } from "../stand-types";
import type { TID } from "graphql/type/common-gql-types";
import type { TUser } from "graphql/type/user-gql-types";


type IStandsTableProps = {
    eeId    : TID
    filters : TStandFilters
};

const StandsEquipmentListTable : React.FC<IStandsTableProps> = ({ filters, eeId }) : JSX.Element  => {

    const rVar = reactiveVarHelper(),
          me = rVar.get('me') as TUser;

    const { text,  ...filterGroups  } = filters;

    const { data : { standsCursor } = { },
        loading,
        // error
    } = useQuery( GET_STANDS_FOR_EQUIPMENT, {
        variables : {
            text : text,
            lang : me.lang_api,
            where : whereConditionBuilder({
                eeId : !eeId ? {} : {
                    column : "EE_ID",
                    operator : "EQ",
                    value : eeId
                },
                ...filterGroups.where,
            }),
            ...(filterGroups.whereCad && {
                whereCad : whereConditionBuilder(filterGroups.whereCad)
            }),
            orderBy : [ { column : "ID", order : "DESC" } ]
        },
        fetchPolicy : "cache-and-network"
    });

    const { data : { standEquipmentOrderTotal } = { },
        loading : loadingTotal,
        // error
    } = useQuery( GET_STAND_EQUIPMENT_ORDER_TOTAL, {
        variables : {
            lang : me.lang_api,
            ee_id : eeId
        },
        fetchPolicy : "cache-and-network",
        nextFetchPolicy : "cache-only"
    });

    const { edges : stands = [] } = standsCursor || {};
    const { items : allEquipment = []  } = standEquipmentOrderTotal || {};

    const { dataMap, totalColumnWidth, columnsMap } = StandHelpers.equipmentList;
    const allColumns = columnsMap(allEquipment);

    return(
        <div className="stands-table-wrap">
            { !!stands.length && allColumns.length &&
                <Table
                    className   = "stands-equipment-list-table"
                    columns     = { allColumns! }
                    dataSource  = { dataMap( stands ) }
                    scroll      = {{ x : totalColumnWidth() }}
                    pagination  = { false }
                />
            }
            { (loading || loadingTotal) && <Loader type={ "block" } /> }
        </div>
    );
};

export default StandsEquipmentListTable;