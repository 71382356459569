import { gql, TypedDocumentNode } from "@apollo/client";

import {
    IQueryVariables,
    IQueryVariablesPaginator,
    TTableFilterPaginatorData,
    TVariableListCursorData
} from "../type/common-gql-types";


export const GET_VARIABLE_LISTS: TypedDocumentNode<{variableListsCursor: TVariableListCursorData}, IQueryVariables > = gql`
    query VariableListsCursor (
        $text    : String
        $where   : QueryVariableListsCursorWhereWhereConditions
        $orderBy : [QueryVariableListsCursorOrderByOrderByClause!]
        $after   : String
    ){
        variableListsCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 500
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    group_name
                    slug
                    value
                    img_path
                }
            }
        }
    }
`;

export const GET_TABLE_FILTER_REQUESTS: TypedDocumentNode<
    { tableFilterTypeRequests: TTableFilterPaginatorData },
    IQueryVariablesPaginator
> = gql`
    query TableFilterTypeRequests (
        $text    : String
        $where   : QueryTableFilterTypeRequestsWhereWhereConditions
        $orderBy : [QueryTableFilterTypeRequestsOrderByOrderByClause!]
        $page    : Int
    ){
        tableFilterTypeRequests(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 50
            page    : $page
        ) {
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                perPage
                total
            }
            data {
                id       
                title   
                query   
                type    
                status  
                data    
                team_id 
                user_id
                relUser {
                    id
                    name
                    surname
                }
                relTeam {
                    id
                    title
                }
                created_at
            }
        }
    }
`;
