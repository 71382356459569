import React, { JSX }  from "react";

import { Select } from "antd";
import { SelectProps } from "rc-select/lib/Select";

import { ETaskFilterFieldTypes } from "../task-types";
import { Icons } from "components/layout";
import Localize from "components/service/Localize";


type TTaskListFilterSelectProps = {
    filteredTypes : ETaskFilterFieldTypes[]
    defaultValue  : ETaskFilterFieldTypes
    onChange     ?: SelectProps['onChange']
};

const { Option } = Select;

const TaskListFilterTypesSelect : React.FC<TTaskListFilterSelectProps> = (
    {
        filteredTypes,
        defaultValue ,
        ...props
    }): JSX.Element => {

    return(
        <Select
            className     = { `task-list-filter-type-select` }
            suffixIcon    = { <Icons.Arrow /> }
            defaultValue  = { defaultValue }
            virtual       = { false }
            { ...props }
        >
            <Option key={ ETaskFilterFieldTypes.NONE } value={ ETaskFilterFieldTypes.NONE  }>
                <Localize>GLOBAL.Placeholder_Text_NotSelected</Localize>
            </Option>
            {(Object.keys(ETaskFilterFieldTypes) as Array<keyof typeof ETaskFilterFieldTypes>)
                .filter( ( item ) => {
                    return !filteredTypes.includes(ETaskFilterFieldTypes[item]);
                } )
                .map(
                    (key) => {
                        if(key === 'NONE') return null;

                        return(
                            <Option key={ key } value={ ETaskFilterFieldTypes[key] }>
                                <Localize>{ `TASK.Select_Option_Filter_${ ETaskFilterFieldTypes[key] }` }</Localize>
                            </Option>
                        );
                    })
            }
        </Select>
    );
};

export default TaskListFilterTypesSelect;