import React, { JSX, useEffect, useState } from "react";

import { FormInstance, Radio } from "antd";

import LayoutForms from "../../forms";
import LayoutHooks from "../../hooks";
import LayoutList from "../../lists";

import type { TFilterTypeName } from "common/types";
import type { TTableFilterInput, TTableFilterItem } from "graphql/type/common-gql-types";


type TActionType = 'new' | 'existed';

interface IFiltersPresetSaveBlockProps {
    query :  TFilterTypeName
    setCallback ?: (
        props : {
            callback  : () => void
        }
    ) => void
}

const FiltersPresetSaveBlock : React.FC<IFiltersPresetSaveBlockProps> = (
    {
        query,
        setCallback
    }) : JSX.Element => {

    const [ actionType, setActionType ] = useState<TActionType>('new'),
          { tableFilterAction, loading } = LayoutHooks.mutation.filterPreset({ isCreate : actionType === 'new' }),
          [ form, setForm ] = useState<FormInstance | undefined>(undefined);

    const [ currentItem, setCurrentItem ] = useState<TTableFilterItem | undefined>(undefined);

    useEffect(() => {
        if(setCallback && form){
            if(actionType === 'new'){
                setCallback( {
                    callback : () => {
                        form?.submit();
                        //console.log("NEW");
                    },
                } );
            }
            if(actionType === 'existed'){
                setCallback( {
                    callback : () => {
                        //console.log("EXISTED", currentItem);
                        const data = localStorage.getItem('tmpFilterFields');

                        if(currentItem){
                            tableFilterAction({
                                variables : {
                                    input : {
                                        id : currentItem.id,
                                        data
                                    } as TTableFilterInput
                                }
                            });
                        }
                    },
                } );
            }
        }

    }, [ actionType, form, currentItem ]);

    return (
        <div className="filter-preset-save-block">
            <div className="action-type-wrap">
                <Radio.Group
                    onChange={ (e) => {
                        setActionType(e.target.value);
                    } }
                    value={ actionType }
                >
                    <Radio key={ 0 } value='new' >
                        Save new
                    </Radio>
                    <Radio key={ 1 } value='existed' >
                        Update existed
                    </Radio>
                </Radio.Group>
            </div>
            <div className="data-wrap">
                { actionType === 'existed' &&
                    <LayoutList.FiltersPreset
                        query       = { query }
                        onlyMy
                        onChange    = { setCurrentItem }
                    />
                }
                { actionType === 'new' &&
                    <LayoutForms.FiltersPresetSave
                        query    = { query }
                        setForm  = { setForm }
                        loading  = { loading }
                        onSubmit = { ({ variables }) => {
                            tableFilterAction({ variables });
                        }}
                    />
                }
            </div>
        </div>
    );
};

export default FiltersPresetSaveBlock;