import React, { JSX } from "react";

import { Input } from "antd";

import GraphicsFields from "../fields";
import GraphicsHelpers from "../helpers";
import { reactiveVarHelper } from "common/helpers";
import Exhibition from "components/exhibition";
import { Blocks, Fields } from "components/layout";

import { EGraphicsFilterFieldTypes, type TGraphicsFilters } from "components/graphics/graphics-types";
import type { IFilterReactiveVar } from "graphql/reactive-variables";
import type { TID } from "graphql/type/common-gql-types";


type TGraphicsListFilterProps = {
    eeId        : TID
    filters     : TGraphicsFilters,
    setFilters  : React.Dispatch<React.SetStateAction<TGraphicsFilters>>
    callBack   ?: () => void
};

const GraphicsListFilterForm : React.FC<TGraphicsListFilterProps> = (
    {
        eeId,
        filters,
        setFilters,
        callBack = () => {},
    }) : JSX.Element => {

    const rVarFilter = reactiveVarHelper('filter');
    const { GraphicsList : preset } = rVarFilter.get('currentPresets') as IFilterReactiveVar["currentPresets"];

    return (
        <Blocks.Filter.Form
            filterFields     = { GraphicsHelpers.filter.parse(filters) }
            fieldTypes       = { EGraphicsFilterFieldTypes }
            defaultFiledType = { EGraphicsFilterFieldTypes.NONE }
            translationKey   = { 'GRAPHICS.Select_Option_Filter' }
            filterTypeName   = { "GraphicsList" }
            onReset={
                () => {
                    setFilters({ text : '' });
                    callBack();
                }
            }
            onChange = {
                (fields) => {
                    localStorage.setItem(
                        "tmpFilterFields",
                        JSON.stringify({
                            text : filters.text,
                            ...GraphicsHelpers.filter.build(fields)
                        })
                    );
                }
            }
            onApply={
                (fields, saveFilter) => {
                    const newFilterData = JSON.stringify({
                        text : filters.text,
                        ...GraphicsHelpers.filter.build(fields)
                    });

                    if(preset?.data !== newFilterData){
                        saveFilter( newFilterData );
                    }

                    setFilters({
                        text : filters.text,
                        ...GraphicsHelpers.filter.build(fields)
                    });
                    callBack();
                }
            }
        >
            {
                ({
                     fieldSettings,
                     filteredTypes,
                     fieldItem : { type, value/*,  label */ }
                 }) => {

                    switch (type){
                        case EGraphicsFilterFieldTypes.BLOCK:
                        case EGraphicsFilterFieldTypes.STAND_NUMBER:
                        case EGraphicsFilterFieldTypes.COMPANY_NAME:
                            return (
                                <Blocks.Filter.Item<EGraphicsFilterFieldTypes> { ...fieldSettings }>
                                    <Input />
                                </Blocks.Filter.Item>
                            );
                        case EGraphicsFilterFieldTypes.HALL:
                            return (
                                <Blocks.Filter.Item<EGraphicsFilterFieldTypes> { ...fieldSettings }>
                                    <Exhibition.Fields.HallSelect
                                        eeId  = { Number(eeId) }
                                    />
                                </Blocks.Filter.Item>
                            );
                        case EGraphicsFilterFieldTypes.MP_STATUS:
                            return (
                                <Blocks.Filter.Item<EGraphicsFilterFieldTypes> { ...fieldSettings }>
                                    <Fields.FromVariablesSelect
                                        variableGroup  = {'mp status'}
                                    />
                                </Blocks.Filter.Item>
                            );
                        case EGraphicsFilterFieldTypes.STATUS:
                            return (
                                <Blocks.Filter.Item<EGraphicsFilterFieldTypes> { ...fieldSettings }>
                                    <Fields.FromVariablesSelect
                                        variableGroup  = {'graphic status'}
                                    />
                                </Blocks.Filter.Item>
                            );
                        case EGraphicsFilterFieldTypes.NONE:
                        default:
                            return (
                                <Blocks.Filter.Item<EGraphicsFilterFieldTypes>
                                    { ...fieldSettings }
                                    noValue = { true }
                                >
                                    <GraphicsFields.FilterTypeSelect
                                        filteredTypes = { filteredTypes as EGraphicsFilterFieldTypes[]}
                                        defaultValue  = {
                                            value && value.length ?
                                                value as EGraphicsFilterFieldTypes :
                                                type as EGraphicsFilterFieldTypes
                                        }
                                    />
                                </Blocks.Filter.Item>
                            );
                    }
                }}
        </Blocks.Filter.Form>
    );
};

export default GraphicsListFilterForm;