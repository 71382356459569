import { useEffect, useState } from "react";

import useCheckDefaultFilterPresets from "./use-check-default-filter-presets";
import useMe from "./use-me";
import useUserMeta from "./use-user-meta";
import useVariableLists from "./use-variable-lists";
import { reactiveVarHelper } from "common/helpers";
import { Hooks } from "components/layout";

import type { TFilterPresetTypes } from "common/types";

const usePrivateAppInit = () => {

    const [ allLoaded, setAllLoaded ] = useState(false);

    const { loading, me/*, error */ } = useMe("cache-and-network", true),
          { variableLists, loading : VRLoading } =  useVariableLists(
            "no-cache",
            true,
            me && me.lang_app
          ),
          { userMetas, loading : UMLoading } =  useUserMeta(
          {
              variables : {
                  userId : me?.id
              },
              fetchPolicy : 'cache-first',
              withStatus : true,
              skip : VRLoading
          }
      );

    const filterPresetMeta = userMetas?.filter(( item ) => {
        return [ "currentFilter", "defaultFilter" ].includes(item.meta_group);
    }) || [];

    const filterIds = filterPresetMeta
                                .map((item) => item.meta_value)
                                .filter((value, index, self) => self.indexOf(value) === index);

    const { filterPresets , loading : FPLoading } = Hooks.query.filterPreset(
          {
            filterId : filterIds
        },
        "cache-first",
        true,
        filterIds.length === 0 || UMLoading
    );

    useCheckDefaultFilterPresets({
        metaDefaultFilters : filterPresetMeta.filter(( item ) => {
            return [ "defaultFilter" ].includes(item.meta_group);
        }) || [],
        loading : (loading || UMLoading || VRLoading || FPLoading)
    });

    useEffect( () => {

        if( me && variableLists){
            reactiveVarHelper().set({
                defaultLanguage : me.lang_app,
                "me" : me,
                'variableLists' : variableLists
            });
        }
    }, [ me, variableLists, userMetas ]);

    useEffect( () => {
        if(userMetas){
            reactiveVarHelper('filter').set({  metaFilters : filterPresetMeta });
        }
    }, [ userMetas ]);

    useEffect(() => {
        if(filterPresets.length > 0 && userMetas && userMetas.length > 0) {

            const presets : { [ key : string ] : TFilterPresetTypes }   = {
                defaultFilter : {},
                currentFilter : {}
            };

            filterPresetMeta.forEach( ( metaItem) => {
                const filterPreset = filterPresets.find(
                    (preset) => preset.id === metaItem.meta_value
                );

                if(filterPreset){
                    presets[metaItem.meta_group] = {
                        ...presets[metaItem.meta_group],
                        [filterPreset.query] : filterPreset
                    } ;
                }
            });

            reactiveVarHelper('filter').set({
                'defaultPresets' : presets.defaultFilter,
                'currentPresets' : presets.currentFilter
            });

            setAllLoaded(true);
        }
    }, [ filterPresets, userMetas ]);


    return !allLoaded;
};

export default usePrivateAppInit;