import { useQuery } from "@apollo/client";

import { GET_EE } from "graphql/query/exhibition-q.gql";

import { IUseGraphQLHook, IUseGraphQLHookProps } from "common/types";
import type { TID } from "graphql/type/common-gql-types";


interface IUseExhibitionEventProps extends IUseGraphQLHookProps{
    eeId  ?: TID
}

interface IUseExhibitionEventPayload extends IUseGraphQLHook{
    exhibitionEvent : any | null
}

const useExhibitionEvent = (
    {
        fetchPolicy = "cache-only",
        withStatus  = false,
        eeId,
    } : IUseExhibitionEventProps ) : IUseExhibitionEventPayload  => {

    const {
        data : { exhibitionEvent  } = { },
        loading,
        error
    } = useQuery( GET_EE, {
        variables : {
            id : eeId
        },
        skip : !eeId,
        fetchPolicy
    });

    return (withStatus) ?
        {
            exhibitionEvent,
            loading,
            error
        } :
        { exhibitionEvent };


};

export default useExhibitionEvent;