import ArrowIcon from "./Arrow-icon";
import AttachIcon from "./Attach-icon";
import CalendarIcon from "./Calendar-icon";
import CheckIcon from "./Check-icon";
import CheckListIcon from "./Check-list-icon";
import CommentIcon from "./Comment-icon";
import CrossIcon from "./Cross-icon";
import DeleteIcon from "./Delete-icon";
import DocumentIcon from "./Document-icon";
import DocumentsIcon from "./Documents-icon";
import DownloadIcon from "./Download-icon";
import EditIcon from "./Edit-icon";
import EquipmentListIcon from "./Equipment-list-icon";
import ExpoDetailsIcon from "./Expo-details-icon";
import EyeIcon from "./Eye-icon";
import FilterIcon from "./Filter-icon";
import FilterPresetIcon from "./FilterPreset-icon";
import FlagIcon from "./Flag-icon";
import GraphicListIcon from "./Graphic-list-icon";
import ImportanceIcon from "./Importance-icon";
import InformationIcon from "./Info-icon";
import LinkIcon from "./Link-icon";
import LockIcon from "./Lock-icon";
import LogoIcon from "./Logo-icon";
import LogoutIcon from "./Logout-icon";
import MinusIcon from "./Minus-icon";
import MovableIcon from "./Movable-icon";
import NotificationsIcon from "./Notifications-icon";
import PlusIcon from "./Plus-icon";
import ProfileIcon from "./Profile-icon";
import SearchIcon from "./Search-icon";
import SettingPresetIcon from "./Setting-preset-icon";
import StandCountIcon from "./Stand-count-icon";
import StandListIcon from "./Stand-list-icon";
import SubstituteIcon from "./Substitute-icon";
import TaskListIcon from "./Task-list-icon";
import ThreeDotIcon from "./Three-dot-icon";
import UploadIcon from "./Upload-icon";
import ZoomIcon from "./Zoom-icon";


const Icons = {
    Arrow : ArrowIcon,
    Attach : AttachIcon,
    Calendar : CalendarIcon,
    Check : CheckIcon,
    CheckList : CheckListIcon,
    Comment : CommentIcon,
    Cross : CrossIcon,
    Delete : DeleteIcon,
    Document : DocumentIcon,
    Documents : DocumentsIcon,
    Download : DownloadIcon,
    Edit : EditIcon,
    Eye : EyeIcon,
    ExpoDetails : ExpoDetailsIcon,
    Filter : FilterIcon,
    FilterPreset : FilterPresetIcon,
    Flag : FlagIcon,
    GraphicList : GraphicListIcon,
    Importance : ImportanceIcon,
    Info : InformationIcon,
    Link : LinkIcon,
    Logo : LogoIcon,
    Logout : LogoutIcon,
    Lock : LockIcon,
    Minus : MinusIcon,
    Movable : MovableIcon,
    Notifications : NotificationsIcon,
    Plus : PlusIcon,
    Profile : ProfileIcon,
    Search : SearchIcon,
    SettingPreset : SettingPresetIcon,
    StandCount : StandCountIcon,
    StandList : StandListIcon,
    Substitute : SubstituteIcon,
    ThreeDot : ThreeDotIcon,
    Upload : UploadIcon,
    Zoom : ZoomIcon,
    TaskList : TaskListIcon,
    EquipmentList : EquipmentListIcon
};

export default Icons;