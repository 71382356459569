import { gql, TypedDocumentNode } from '@apollo/client';

import { TLocales } from "../../common/types";
import { TEquipmentCursorData, TEquipmentOrderCursorData, TEquipmentOrderItem } from "../type/equipment-gql-types";

import type { IQueryVariables, TID, TWhereCondition } from "../type/common-gql-types";


interface IEquipmentQueryVariables extends IQueryVariables{
    groupId             ?: TID
    whereExhibitionType ?: TWhereCondition
    whereSubstitudes    ?: TWhereCondition
}

export const GET_EQUIPMENTS : TypedDocumentNode<
        { equipmentsCursor : TEquipmentCursorData },
        IEquipmentQueryVariables
    > = gql`
    query GetEquipments (
        $groupId : ID
        $text    : String
        $where   : QueryEquipmentsCursorWhereWhereConditions
        $whereExhibitionType : QueryEquipmentsCursorWhereExhibitionTypeEquipmentWhereHasConditions
        $whereSubstitudes    : QueryEquipmentsCursorWhereEquipmentSubstitudesWhereHasConditions
        $orderBy : [QueryEquipmentsCursorOrderByOrderByClause!]
        $after   : String
    ){
        equipmentsCursor(
            group_id : $groupId
            text     : $text
            where    : $where
            whereExhibitionTypeEquipment : $whereExhibitionType
            whereEquipmentSubstitudes    : $whereSubstitudes
            orderBy  : $orderBy
            first    : 50
            after    : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node {
                    id
                    group_id
                    title
                    lang
                    type
                    navision_number
                    octacad_number
                    color
                    supplier_article
                    supplier_id
                    description
                    getImages {
                        id
                        title
                        server_name
                        sizes
                    }
                    updated_at
                    created_at
                }
            }
        }
    }
`;

export const GET_STAND_EQUIPMENT_ORDERS : TypedDocumentNode<
        { standEquipmentOrdersCursor : TEquipmentOrderCursorData },
        IQueryVariables
    > = gql`
    query GetStandEquipmentOrders (
        $text    : String
        $where   : QueryStandEquipmentOrdersCursorWhereWhereConditions
        $orderBy : [QueryStandEquipmentOrdersCursorOrderByOrderByClause!]
        $after   : String
    ){
        standEquipmentOrdersCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 25
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node {
                    id
                    ee_id
                    stand_id
                    equipment_group
                    set_type
                    qty_default
                    qty
                    price
                    equipment_variation_id
                    relEquipment {
                        id
                        group_id
                        title
                        lang
                        type
                        navision_number
                        octacad_number
                        color
                        supplier_article
                        supplier_id
                        description
                        supplier {
                            id
                            title
                        }
                        getImages {
                            id
                            title
                            server_name
                            sizes
                        }
                        updated_at
                        created_at
                    }
                    relEquipmentVariation {
                        id
                        equipment_group
                        title
                        organizer_id
                        article
                        price
                    }
                    created_at
                    updated_at
                }
            }
        }
    }
`;


export const GET_STAND_EQUIPMENT_ORDER : TypedDocumentNode<
    { standEquipmentOrder : TEquipmentOrderItem },
    { orderId : TID }
> = gql`
    query GetStandEquipmentOrder (
        $orderId  : ID!
    ){
        standEquipmentOrder(
            id: $orderId
        ) {
            id
            ee_id
            stand_id
            equipment_group
            set_type
            qty_default
            qty
            price
            equipment_variation_id
            relEquipment {
                id
                group_id
                title
                lang
                type
                navision_number
                octacad_number
                color
                supplier_article
                supplier_id
                description
                supplier {
                    id
                    title
                }
                getImages {
                    id
                    title
                    server_name
                    sizes
                }
                updated_at
                created_at
            }
            relEquipmentVariation {
                id
                equipment_group
                title
                organizer_id
                article
                price
            }
            created_at
            updated_at
        }
    }
`;

export const GET_STAND_EQUIPMENT_ORDER_TOTAL : TypedDocumentNode<
    {
        standEquipmentOrderTotal : {
            items : TEquipmentOrderItem[],
            total_qty : number,
            total_items : number
        }
    },
    {
        ee_id : TID,
        lang : TLocales
    }
> = gql`
    query GetStandEquipmentOrderTotal (
        $ee_id  : ID!
        $lang   : String
    ){
        standEquipmentOrderTotal(
            ee_id: $ee_id
        ) {
            total_qty
            total_items
            items {
                id
                ee_id
                stand_id
                equipment_group
                price
                equipment_variation_id
                total_qty
                relEquipment(
                    lang: $lang
                ) {
                    id
                    group_id
                    title
                    lang
                    type
                    navision_number
                    octacad_number
                    supplier_article
                    supplier_id
                }
                created_at
            }
        }
    }
`;