import React, { JSX, useEffect, useState } from "react";

import { Radio } from "antd";

import FiltersPresetListActions from "./Filters-preset-list-actions";
import LayoutHooks from "../../hooks";
import Icons from "../../icons";
import { reactiveVarHelper } from "common/helpers";
import { Loader } from "components/request-result";
import Localize from "components/service/Localize";

import type { TFilterTypeName } from "common/types";
import type { IFilterReactiveVar } from "graphql/reactive-variables";
import type { TFilterStatus, TTableFilterItem } from "graphql/type/common-gql-types";
import type { TUser } from "graphql/type/user-gql-types";


interface IFiltersPresetListProps {
    onlyMy ?: boolean,
    query   : TFilterTypeName
    status ?: TFilterStatus,
    setCallback ?: (
        props : {
            callback  : () => void
        }
    ) => void,
    onChange ?: ( item : TTableFilterItem ) => void
}

const FiltersPresetList : React.FC<IFiltersPresetListProps> = (
    {
        onlyMy = false,
        query,
        onChange = () => {},
    }) : JSX.Element => {

    const me : TUser = reactiveVarHelper().get('me'),
          rVarFilter = reactiveVarHelper('filter');

    const  { defaultPresets, currentPresets } = rVarFilter.get('all')  as IFilterReactiveVar;

    const [ value, setValue ] = useState(currentPresets[query!]?.id);

    const { filterPresets, loading } = LayoutHooks.query.filterPreset(
        {
            ...(onlyMy && { userId : me.id }),
            ...(query && { query }),
            ...(!onlyMy && {
                userId : me.id,
                status : 'public'
            })
        },
        "cache-and-network",
        true
    );

    useEffect(() => {
        const currentItem = filterPresets.find( (item) => item.id === value );

        if(currentItem){
            onChange( currentItem );
        }
    }, [ value ]);

    return(
        <div className={ `filter-preset-radio-list radio-select-wrap ${ loading ? "loading" : "" }`} >
            { loading ?
                <Loader /> :
                <>
                    { ([ defaultPresets[query]  ,...filterPresets ].length > 0) &&
                        <Radio.Group
                            onChange={(e) => {
                                setValue(e.target.value);
                            }}
                            value={value}
                        >
                            { ([ defaultPresets[query]  ,...filterPresets ]).map(
                                (preset) => {
                                    if(!preset){
                                        return null;
                                    }

                                    return (
                                        <div className="radio-row" key={ preset.id }>
                                            <Radio key={ preset.id } value={ preset.id }>
                                                <div className="title">
                                                    { preset.status === 'default' ?
                                                        <Localize>GLOBAL.Option_Label_DefaultFilter</Localize> :
                                                        preset.title
                                                    }
                                                    { preset.status !== 'public' &&
                                                        <Icons.Eye type="closed"/>
                                                    }
                                                </div>
                                                {preset.user_id === me.id ?
                                                    <div className="user me">
                                                        { preset.status === 'default' ? 'Default' : 'My view'}
                                                    </div> :
                                                    <div className="user">
                                                        <Icons.Profile type="simple"/>
                                                        { preset.relUser.name } { preset.relUser.surname }
                                                    </div>
                                                }
                                            </Radio>
                                            <div className="action">
                                                <FiltersPresetListActions
                                                    filterPreset={{
                                                        id : preset.id,
                                                        user_id : preset.user_id,
                                                        status : preset.status,
                                                        name : preset.title
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Radio.Group>
                    }
                    { filterPresets.length === 0 && !defaultPresets[query] && !loading &&
                        <div className="no-data">
                            <Localize
                                wrap tag="p"
                                className="label"
                            >
                                No available filter presets yet
                            </Localize>
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default FiltersPresetList;