import React, { JSX, useState } from "react";

import { useOutletContext } from "react-router-dom";

import { filterDataHelper, reactiveVarHelper } from "common/helpers";
import { Blocks, Buttons } from "components/layout";
import { ROUTES } from "components/routes";
import Localize from "components/service/Localize";
import Task from "components/task";

import type { TTaskFilters } from "components/task/task-types";
import type { IFilterReactiveVar } from "graphql/reactive-variables";

 
const TaskListPage : React.FC = () : JSX.Element => {

    const rVarFilter = reactiveVarHelper('filter'),
        { currentPresets } = rVarFilter.get('all') as IFilterReactiveVar;

    const { ee } = useOutletContext<{ee : any}>();
    const [ filters, setFilters ] = useState<TTaskFilters>({
        text : "",
        ...filterDataHelper.parsePreset('StandList', currentPresets),
    });

    return(
        <div className="page task-list-page">
            <Blocks.PageHeader
                title={
                    <Localize exhibitionName={ ee.title }>
                        TASK.List_PageTitle
                    </Localize>
                }
                backButton = {
                    <Buttons.PageBack
                        path = { ROUTES.EXHIBITION_LIST }
                        text = "NAVIGATION.BackButton_Text_ToExhibitions"
                    />
                }
            />
            <div className="page-content-wrap">
                <Blocks.TableHeader
                    filters = {
                        <Blocks.Filters<TTaskFilters>
                            state = {{ filters, setFilters }}
                            name  = { "TaskList" }
                        >
                            <Task.Forms.ListFilter
                                ee         = { ee }
                                filters    = { filters }
                                setFilters = { setFilters }
                            />
                        </Blocks.Filters>
                    }
                />
                <Task.Tables.All
                    eeId    = { ee.id }
                    filters = { JSON.parse(JSON.stringify(filters)) }
                />
            </div>
        </div>
    );

};

export default TaskListPage;