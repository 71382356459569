import type { TWhereCondition } from "graphql/type/common-gql-types";

export type TEquipmentFilterGroups = 'where'| 'whereCad';
export type TEquipmentFilterFields = Partial<Record<`${EEquipmentFilterFieldTypes}`, TWhereCondition>>;

export type TEquipmentFilters = Partial<Record<TEquipmentFilterGroups, TEquipmentFilterFields>> & Record<'text', string>;

export enum EEquipmentFilterFieldTypes  {
    NONE = 'none',
    COMPANY_NAME = 'companyName',
    HALL = 'hall',
    BLOCK = 'block',
    STAND_NUMBER = 'standNumber',
    CAD_NUMBER = 'cadNumber',
}