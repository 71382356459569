
import { EEquipmentFilterFieldTypes, TEquipmentFilterGroups, TEquipmentFilters } from "../equipment.types";

import type { TFilterFieldItem, TFilterFields } from "common/types";
import type { TWhereCondition } from "graphql/type/common-gql-types";


const equipmentFilterParse = (filters : TEquipmentFilters) : TFilterFieldItem<EEquipmentFilterFieldTypes>[] => {

    const fields : TFilterFieldItem<EEquipmentFilterFieldTypes>[] = [];

    (Object.keys(filters) as Array<TEquipmentFilterGroups | 'text'>).forEach( ( group ) => {

        if(group !== 'text'){
            const filterGroup = filters[group] as Omit<TFilterFields, 'text'>;

            Object.keys(filterGroup).forEach(( filterItemKey ) => {

                const item = filterGroup[filterItemKey] as TWhereCondition;
                let type;

                switch(`${ group }_${filterItemKey}`){
                    case 'whereCad_cadNumber': type = EEquipmentFilterFieldTypes.CAD_NUMBER; break;
                    case 'where_hall'        : type = EEquipmentFilterFieldTypes.HALL; break;
                    case 'where_block'       : type = EEquipmentFilterFieldTypes.BLOCK; break;
                    case 'where_companyName' : type = EEquipmentFilterFieldTypes.COMPANY_NAME; break;
                    case 'where_standNumber' : type = EEquipmentFilterFieldTypes.STAND_NUMBER; break;
                }

                const value = typeof item.value === 'string' ? item.value.replace('%', '') : item.value;

                fields.push({
                    key : Date.now().toString(),
                    type : type!,
                    saveable : true,
                    label : item.label,
                    value : value as string
                });
            });
        }
    });

    return fields;
};

const equipmentFilterBuild = (fields : TFilterFieldItem<EEquipmentFilterFieldTypes>[]): Omit<TEquipmentFilters, 'text'> => {

    const where: TEquipmentFilters["where"] = {},
        whereCad : TEquipmentFilters["whereCad"] = {};

    fields.forEach( (item) => {

        if(item.saveable && item.value !== '') {
            const filter : TWhereCondition = {
                column : '',
                operator : "EQ",
                value : item.value,
                label : item.label
            };

            switch( item.type ){
                case EEquipmentFilterFieldTypes.HALL:
                    where[item.type] = { ...filter, column : 'HALL_ID' };
                break;
                case EEquipmentFilterFieldTypes.CAD_NUMBER:
                    whereCad[item.type] = {
                        ...filter,
                        column : 'CAD_NUMBER',
                        operator : 'LIKE',
                        value : `${item.value}%`
                    };
                    break;
                case EEquipmentFilterFieldTypes.COMPANY_NAME:
                    where[item.type] = {
                        ...filter,
                        column : 'COMPANY_NAME',
                        operator : 'LIKE',
                        value : `${item.value}%`
                    };
                break;
                case EEquipmentFilterFieldTypes.BLOCK:
                    where[item.type] = {
                        ...filter,
                        column : 'BLOCK',
                        operator : 'LIKE',
                        value : `${item.value}%`
                    };
                break;
                case EEquipmentFilterFieldTypes.STAND_NUMBER:
                    where[item.type] = {
                        ...filter,
                        column : 'STAND_NUMBER',
                        operator : 'LIKE',
                        value : `${item.value}%`
                    };
                break;
            }
        }
    });

    return {
        ...(Object.keys(where).length > 0 && { where }),
        ...(Object.keys(whereCad).length > 0 && { whereCad }),
    };
};


const standFilterHelper = {
    parse : equipmentFilterParse,
    build : equipmentFilterBuild
};

export default standFilterHelper;