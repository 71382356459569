import { useQuery } from "@apollo/client";

import { GET_USER_META } from "graphql/query/user-q.gql";

import type { IUseGraphQLHook, IUseGraphQLHookProps } from "common/types";
import type { TID, TMetaGroup, TMetaItem } from "graphql/type/common-gql-types";


interface IUseUserMetaProps extends IUseGraphQLHookProps{
    variables : {
        id        ?: TID
        userId    ?: TID
        metaKey   ?: string
        metaGroup ?: TMetaGroup
    }
}

interface IUseUserMetaPayload extends IUseGraphQLHook{
    userMetas : TMetaItem[] | undefined
}

const useUserMeta = (
    {
        fetchPolicy = "cache-only",
        withStatus  = false,
        variables,
    } : IUseUserMetaProps ) : IUseUserMetaPayload  => {

    const {
        data : { userMetas } = { },
        loading,
        error
    } = useQuery( GET_USER_META, {
        variables : { ...variables },
        skip : !variables.userId,
        fetchPolicy
    });

    return (withStatus) ?
        {
            userMetas,
            loading,
            error
        } :
        { userMetas };


};

export default useUserMeta;