import { gql, useApolloClient } from "@apollo/client";

import type { TID } from "graphql/type/common-gql-types";
import type { TGraphicsSupplier } from "graphql/type/graphics-gql-types";
 

const useGraphicsSupplierFragment = (
        { supplierId }  :  { supplierId : TID }
    ) : TGraphicsSupplier | undefined => {

    const client = useApolloClient();

    return client.readFragment({
        id : `GraphicsSupplier:${ supplierId }`,
        fragment : gql`
            fragment GraphicsSupplierFields on GraphicsSupplier {
                id
                title
                organizer_id
                supplier_id
                relVariableList {
                    id
                    group_name
                    slug
                }
                relRule {
                    id
                    title
                    graphic_supplier_id
                    price_manufacture
                    price_on_supplier
                    price_on_stand
                    price_additional
                    free_symbols
                    lettering
                }
                created_at
                updated_at
            }
        `
    }) || undefined;
};

export default useGraphicsSupplierFragment;