import { TTranslations } from "common/types";


const english : TTranslations = {
    'FORM_RULES.Required_Login' : 'Please input your Login',
    'FORM_RULES.Required_Password' : 'Please input your Password',
    'FORMS.Input_Label_Login' : 'login',
    'FORMS.Input_Label_Password' : 'password',
    'FORMS.Button_Text_Login' : 'Sign in',
    'CHECKLIST.Menu_Label_ExpoDates' : 'Expo dates { status }',
    'CHECKLIST.Menu_Label_Crafts' : 'Crafts { status }',
    'CHECKLIST.Menu_Label_SitePlanning' : 'Site planning { status }',
    'CHECKLIST.Menu_Label_Participants' : 'Participants { status }',
    'CHECKLIST.Menu_Label_Invoice' : 'Invoice { status }',
    'CHECKLIST.Menu_Label_Recalculation' : 'Recalculation { status }',
    'CHECKLIST.Form_Title_OfficialDates' : 'Official dates',
    'CHECKLIST.Form_Title_AdditionalDates' : 'Additional dates',
    'CHECKLIST.Form_Title_InternalDates' : 'Internal dates',
    'CHECKLIST.Form_Title_Logistics' : 'Transportation/logistics/empties',
    'CHECKLIST.Form_Title_Assembly' : 'Assembly',
    'CHECKLIST.Form_Title_StandConstruction' : 'mp-internal / stand construction',
    'CHECKLIST.Form_Title_FloorCoverings' : 'Floor coverings',
    'CHECKLIST.Form_Title_Furniture' : 'Furniture',
    'CHECKLIST.Form_Title_Graphics' : 'Graphics',
    'CHECKLIST.Form_Title_Electric' : 'Electric',
    'CHECKLIST.Form_Title_Team' : 'Team',
    'CHECKLIST.Form_Title_Documents' : 'Documents',
    'CHECKLIST.Form_Title_Organizer' : 'Organizer',
    'CHECKLIST.Form_Title_Subcontractors' : 'Subcontractors',
    'CHECKLIST.Form_Title_TeamOnSite' : 'mp team on site',
    'CHECKLIST.FieldGroup_Label_StartOfConstruction' : 'Start of construction',
    'CHECKLIST.FieldGroup_Label_OpeningTime' : 'Opening time (e.g 24 h)',
    'CHECKLIST.FieldGroup_Label_HandoverDate' : 'Handover date',
    'CHECKLIST.FieldGroup_Label_ExhibitionBegins' : 'Exhibition begins',
    'CHECKLIST.FieldGroup_Label_ExhibitionEnds' : 'Exhibition ends',
    'CHECKLIST.FieldGroup_Label_StartOfDeconstruction' : 'Start of deconstruction',
    'CHECKLIST.FieldGroup_Label_EndOfDeconstruction' : 'End of deconstruction',
    'CHECKLIST.FieldGroup_Label_EarlyConstruction' : 'Early construction',
    'CHECKLIST.FieldGroup_Label_AdditionalCostsForEarlyConstruction' : 'Additional costs for early construction',
    'CHECKLIST.FieldGroup_Label_UnloadingTrucks' : 'Unloading trucks',
    'CHECKLIST.FieldGroup_Label_ExtendedDeconstruction' : 'Extended deconstruction',
    'CHECKLIST.FieldGroup_Label_AdditionalCostsForExtendedDeconstruction' : 'Additional costs for extended deconstruction',
    'CHECKLIST.FieldGroup_Label_LoadingTrucksForReturnTransport' : 'Loading trucks for return transport',
    'CHECKLIST.FieldGroup_Label_DeadlineForChangesToStandConstruction' : 'Deadline for changes to stand construction',
    'CHECKLIST.FieldGroup_Label_DeadlineForSubmittingGraphicsDataForExhibitors' : 'Deadline for submitting graphics data for exhibitors',
    'CHECKLIST.FieldGroup_Label_DeadlineForSubmittingGraphicDataForSpecialAreas' : 'Deadline for submitting graphic data for special areas',
    'CHECKLIST.FieldGroup_Label_ReleasePicking' : 'Release picking',
    'CHECKLIST.FieldGroup_Label_PickingFromTo' : 'Picking (from/ to)',
    'CHECKLIST.FieldGroup_Label_CreateCarnetLists' : 'Create carnet lists (optional)',
    'CHECKLIST.FieldGroup_Label_LIHKAppointment' : 'LIHK appointment (carnet application) (optional)',
    'CHECKLIST.FieldGroup_Label_IHKCarnetExhibition' : 'IHK carnet exhibition (optional)',
    'CHECKLIST.FieldGroup_Label_CustomsTauchaAppointmentsWithTrucks' : 'Customs Taucha appointments with trucks (optional)',
    'CHECKLIST.FieldGroup_Label_Loading' : 'Loading',
    'CHECKLIST.FieldGroup_Label_ArrivalTruckExhibitionGrounds' : 'Arrival truck exhibition grounds',
    'CHECKLIST.FieldGroup_Label_UnloadingTruckExhibitionTrucks' : 'Unloading truck exhibition trucks',
    'CHECKLIST.FieldGroup_Label_CheckSupplierInvoicesInNavision' : 'Check supplier invoices in Navision',
    'CHECKLIST.FieldGroup_Label_Proceeds' : 'Proceeds',
    'CHECKLIST.FieldGroup_Label_PurchaseCosts' : 'Purchase costs',
    'CHECKLIST.FieldGroup_Label_DB1' : 'DB1',
    'CHECKLIST.FieldGroup_Label_Resources' : 'Resources',
    'CHECKLIST.FieldGroup_Label_DB2' : 'DB2',
    'CHECKLIST.FieldGroup_Label_TurnoverOrderBacklog' : 'Turnover/order backlog in EURO',
    'CHECKLIST.FieldGroup_Label_1stPaymentOnAccount' : '1st payment on account',
    'CHECKLIST.FieldGroup_Label_2stPaymentOnAccount' : '2st payment on account',
    'CHECKLIST.FieldGroup_Label_FinalInvoice' : 'Final invoice',
    'CHECKLIST.FieldGroup_Label_RecordLastMinuteInNavision' : 'Record last minute (in Navision)',
    'CHECKLIST.FieldGroup_Label_RecordDamages' : 'Record damages',
    'CHECKLIST.FieldGroup_Label_SettleAisleCarpet' : 'Settle aisle carpet',
    'CHECKLIST.FieldGroup_Label_OutwardAndReturnTransportationForStoredMaterialWhichWasDelivered' : 'Outward and return transportation for stored material which was delivered to the trade fair (poken stands, technology boxes etc.)',
    'CHECKLIST.FieldGroup_Label_Measurement' : 'Measurement',
    'CHECKLIST.FieldGroup_Label_ElectricalConnections' : 'Electrical connections',
    'CHECKLIST.FieldGroup_Label_Unloading' : 'Unloading',
    'CHECKLIST.FieldGroup_Label_CarpetLaying' : 'Carpet laying',
    'CHECKLIST.FieldGroup_Label_EmptiesHandling' : 'Empties handling',
    'CHECKLIST.FieldGroup_Label_EmptiesStorage' : 'Empties storage',
    'CHECKLIST.FieldGroup_Label_Assembly' : 'Assembly',
    'CHECKLIST.FieldGroup_Label_GraphicDesign' : 'Graphic design',
    'CHECKLIST.FieldGroup_Label_Furniture' : 'Furniture',
    'CHECKLIST.FieldGroup_Label_Organizer' : 'Organizer:',
    'CHECKLIST.FieldGroup_Label_PlaceOfTakingPlace' : 'Place of taking place:',
    'CHECKLIST.FieldGroup_Label_ProjectManager1' : 'Project manager 1',
    'CHECKLIST.FieldGroup_Label_ProjectManager2' : 'Project manager 2',
    'CHECKLIST.FieldGroup_Label_ConstructionManager1' : 'Construction manager 1',
    'CHECKLIST.FieldGroup_Label_ConstructionManager2' : 'Construction manager 2',
    'CHECKLIST.FieldGroup_Label_ForwarderOutwardAndReturnTransportation' : 'Forwarder (outward and return transportation)',
    'CHECKLIST.FieldGroup_Label_LogisticsCompanyOnSite' : 'Logistics company on site (unloading/loading/forklift)',
    'CHECKLIST.FieldGroup_Label_CarpetCompanyLaying' : 'Carpet company (laying)',
    'CHECKLIST.FieldGroup_Label_FurnitureSupplier1' : 'Furniture supplier 1',
    'CHECKLIST.FieldGroup_Label_FurnitureSupplier2' : 'Furniture supplier 2',
    'CHECKLIST.FieldGroup_Label_InstallationCompany1' : 'Installation company 1',
    'CHECKLIST.FieldGroup_Label_InstallationCompany2' : 'Installation company 2',
    'CHECKLIST.FieldGroup_Label_InstallationCompany3' : 'Installation company 3',
    'CHECKLIST.FieldGroup_Label_CleaningCompany' : 'Cleaning company',
    'CHECKLIST.FieldGroup_Label_TransportsViaForwardingAgency' : 'Transports via forwarding agency',
    'CHECKLIST.FieldGroup_Label_OrderTrucks' : 'Order trucks',
    'CHECKLIST.FieldGroup_Label_NumberOfTrucks' : 'Number of trucks:',
    'CHECKLIST.FieldGroup_Label_LoadingScheduleWarehouse' : 'Loading schedule warehouse',
    'CHECKLIST.FieldGroup_Label_UnloadingScheduleExhibitionGrounds' : 'Unloading schedule exhibition grounds',
    'CHECKLIST.FieldGroup_Label_LogisticalForUnloading' : 'Logistical for unloading?',
    'CHECKLIST.FieldGroup_Label_InformLogisticsAboutUnloadingAndSetupAndDismantlingTimes' : 'Inform logistics about unloading/and set-up and dismantling times',
    'CHECKLIST.FieldGroup_Label_WhereToStoreEmpties' : 'Where to store empties?',
    'CHECKLIST.FieldGroup_Label_LogisticEmptiesWho' : 'Logistic empties / who?',
    'CHECKLIST.FieldGroup_Label_CostsForEmpties' : 'Costs for empties?',
    'CHECKLIST.FieldGroup_Label_PokeStandLoading' : 'Poke stand loading',
    'CHECKLIST.FieldGroup_Label_DetermineTheNumberOfAssemblyWorkersForAssemblyAndDismantling' : 'Determine the number of assembly workers for assembly and dismantling',
    'CHECKLIST.FieldGroup_Label_DetermineTheNumberOfAssemblyStaffRequiredForService' : 'Determine the number of assembly staff required for service',
    'CHECKLIST.FieldGroup_Label_BookAssemblyStaffViaMario' : 'Book assembly staff (via Mario)',
    'CHECKLIST.FieldGroup_Label_InformAssemblyWorkersAboutTheSchedule' : 'Inform assembly workers about the schedule (start of assembly, etc.)',
    'CHECKLIST.FieldGroup_Label_EstimatedAssemblyCostsOrderMario' : 'Estimated assembly costs (order Mario)',
    'CHECKLIST.FieldGroup_Label_AssemblyCostsPercent' : 'Assembly costs %',
    'CHECKLIST.FieldGroup_Label_IdentifyCriticalMaterialQuantitiesAndCoordinateWithWarehouseConstructionManager' : 'Identify critical material quantities and coordinate with warehouse/construction manager (e.g. AAA tower side panels, garnet display cases, AAA built-in showcases, TVs, halopanels, sideboards, doors, etc.)',
    'CHECKLIST.FieldGroup_Label_PrepareAndActivateELFE' : 'Prepare and activate ELFE',
    'CHECKLIST.FieldGroup_Label_ArticleRequirementPreviewWithFurnitureListsAndMPEquipmentListsForConsistency' : 'Article requirement preview with furniture lists and mp equipment lists for consistency',
    'CHECKLIST.FieldGroup_Label_CoordinateSpecialDesignsWithTheManagers' : 'Coordinate special designs with the managers',
    'CHECKLIST.FieldGroup_Label_ReservesForAidsAndMaterialsDetermineStandConstruction' : 'Reserves for aids and materials determine stand construction (with site manager/Mario)',
    'CHECKLIST.FieldGroup_Label_CarpetSupplier' : 'Carpet supplier',
    'CHECKLIST.FieldGroup_Label_StandardColorCarpetExhibitionStands' : 'Standard color carpet exhibition stands',
    'CHECKLIST.FieldGroup_Label_AisleCarpetYesNo' : 'Aisle carpet yes/no',
    'CHECKLIST.FieldGroup_Label_StandardColorAisleCarpet' : 'Standard color aisle carpet',
    'CHECKLIST.FieldGroup_Label_ForecastCommunicateCarpetQuantitiesToSupplier' : 'Forecast communicate carpet quantities to supplier',
    'CHECKLIST.FieldGroup_Label_CarpetListWithHallPlanToSupplier' : 'Carpet list with hall plan to supplier',
    'CHECKLIST.FieldGroup_Label_PlanAndOrderCarpetReserve' : 'Plan and order carpet reserve',
    'CHECKLIST.FieldGroup_Label_CommunicateStartAndOfInstallationDate' : 'Communicate start of installation date',
    'CHECKLIST.FieldGroup_Label_CommunicateEndOfInstallationDate' : 'Communicate end of installation date',
    'CHECKLIST.FieldGroup_Label_CommunicateAisleLayingDate' : 'Communicate aisle laying date',
    'CHECKLIST.FieldGroup_Label_CommunicateDateForCarpetCollectionAfterDismantling' : 'Communicate date for carpet collection after dismantling',
    'CHECKLIST.FieldGroup_Label_FurnitureSuppliers' : 'Furniture supplier/s',
    'CHECKLIST.FieldGroup_Label_CommunicateForecastToSuppliersAsEarlyAsPossible' : 'Communicate forecast to suppliers as early as possible',
    'CHECKLIST.FieldGroup_Label_DiscussWithSuppliersWhichFurnitureIsInShortSupply' : 'Discuss with suppliers which furniture is in short supply',
    'CHECKLIST.FieldGroup_Label_FindAlternativeSuppliersIfNecessary' : 'Find alternative suppliers if necessary',
    'CHECKLIST.FieldGroup_Label_DetermineAndOrderReserves' : 'Determine and order reserves',
    'CHECKLIST.FieldGroup_Label_OrderFinalFurnitureQuantitiesUsingFurnitureList' : 'Order final furniture quantities using furniture list',
    'CHECKLIST.FieldGroup_Label_SendDistributionListAndCurrentHallPlanToSuppliers' : 'Send distribution list and current hall plan to suppliers',
    'CHECKLIST.FieldGroup_Label_CommunicateDeliveryAndDistributionStartDate' : 'Communicate delivery and distribution start date',
    'CHECKLIST.FieldGroup_Label_CommunicateFurnitureHandoverDate' : 'Communicate furniture handover date',
    'CHECKLIST.FieldGroup_Label_CommunicateServicePeriodReadinessForComplaints' : 'Communicate service period (readiness for complaints)',
    'CHECKLIST.FieldGroup_Label_CommunicateFurnitureDismantlingStartDate' : 'Communicate furniture dismantling start date',
    'CHECKLIST.FieldGroup_Label_CommunicateFurnitureDismantlingEndDate' : 'Communicate furniture dismantling end date',
    'CHECKLIST.FieldGroup_Label_GraphicsSupplier' : 'Graphics supplier',
    'CHECKLIST.FieldGroup_Label_PossiblySupplyMaterialPlatesForLamination' : 'Possibly supply material / plates for lamination (e.g. tower panels AAA Hamburg)',
    'CHECKLIST.FieldGroup_Label_PossiblySupplyCovers' : 'Possibly supply covers (e.g. for PackLoc trade fairs)',
    'CHECKLIST.FieldGroup_Label_CoordinateDeadlineForGraphicDataLayoutsProduction' : 'Coordinate deadline for graphic data/layouts/production',
    'CHECKLIST.FieldGroup_Label_CurrentHallPlanToSupplier' : 'Current hall plan to supplier',
    'CHECKLIST.FieldGroup_Label_CoordinateDateForOnSiteAssembly' : 'Coordinate date for on-site assembly',
    'CHECKLIST.FieldGroup_Label_CoordinateHandoverDate' : 'Coordinate handover date',
    'CHECKLIST.FieldGroup_Label_CoordinateServicePeriodReadinessForComplaints' : 'Coordinate service period (readiness for complaints)',
    'CHECKLIST.FieldGroup_Label_ElectricalPlanFromEasyfairs' : 'Electrical plan from easyfairs with general block connections and additional customer orders',
    'CHECKLIST.FieldGroup_Label_PlanArrivalDepartureHotel' : 'Plan arrival / departure (hotel)',
    'CHECKLIST.FieldGroup_Label_PlanArrivalDeparture' : 'Plan arrival / departure',
    'CHECKLIST.FieldGroup_Label_HotelBookedWhichOneAddress' : 'Hotel booked - which one? Address?',
    'CHECKLIST.FieldGroup_Label_MeansOfTransportation' : 'Means of transportation (e.g. train / company car)',
    'CHECKLIST.FieldGroup_Label_HallPlans' : 'Hall plans',
    'CHECKLIST.FieldGroup_Label_CarpetExcelSheetLayingPlanWithColors' : 'Carpet Excel sheet laying plan with colors',
    'CHECKLIST.FieldGroup_Label_CurrentHallPlanWithPaintedCarpetColor' : 'Current hall plan with painted carpet color',
    'CHECKLIST.FieldGroup_Label_FinalFurnitureListPartList' : 'Final furniture list/part list',
    'CHECKLIST.FieldGroup_Label_DistributionListEquipment' : 'Distribution list equipment (TVs, mediawalls, shelves, winnies, counters, showcases, t-connection profiles, shelves, wardrobe rails for exhibits, wardrobe rails for clothing, pedestals, reading desk, etc.)',
    'CHECKLIST.FieldGroup_Label_PlansForLastMinuteChanges' : 'Plans for last minute changes',
    'CHECKLIST.FieldGroup_Label_PlansForSpecialConstructions' : 'Plans for special constructions',
    'CHECKLIST.FieldGroup_Label_NavisionArticleRequirementPreview' : 'Navision article requirement preview',
    'CHECKLIST.FieldGroup_Label_DeliveryBillsWithPackageNumbersFromStock' : 'Delivery bills with package numbers (from stock)',
    'CHECKLIST.FieldGroup_Label_ReserveListsOfStandConstructionMaterialsAids' : 'Reserve lists of stand construction materials / aids',
    'CHECKLIST.Input_Placeholder_Responsible' : 'Responsible',
    'CHECKLIST.Input_Placeholder_AppointmentsAgreed' : 'Appointments agreed',
    'CHECKLIST.Input_Placeholder_NumberMaterial' : '(Number/Material)',
    'CHECKLIST.Input_Placeholder_CompanyName' : 'Company name',
    'CHECKLIST.Input_Placeholder_PersonName' : 'Person name',
    'CHECKLIST.Input_Placeholder_Phone' : 'Phone { count }',
    'GLOBAL.Input_Placeholder_Email' : 'E-mail',
    'GLOBAL.Datepicker_Placeholder_When' : 'When',
    'GLOBAL.Timepicker_Placeholder_Time' : 'Time',
    'NAVIGATION.Menu_Label_ExpoDetails' : 'Expo { br } details',
    'NAVIGATION.Menu_Label_StandList' : 'Stand { br } List',
    'NAVIGATION.Menu_Label_GraphicsList' : 'Graphics { br } list',
    'NAVIGATION.Menu_Label_CheckList' : 'Check { br } list',
    'NAVIGATION.MainMenu_Label_Exhibitions' : 'Exhibitions',
    'NAVIGATION.MainMenu_Label_Library' : 'Library',
    'NAVIGATION.MainMenu_Label_MyTasks' : 'My tasks',
    'NAVIGATION.BackButton_Text_ToExhibitions' : 'To exhibition list',
    'NAVIGATION.BackButton_Text_ToStandList' : 'To stand list',
    'NAVIGATION.BackButton_Text_ToGraphicsList' : 'To graphics list',
    'GRAPHICS.TableColumn_Header_ClientName' : 'CLIENT NAME  { br } HALL / STAND / BLOCK',
    'GRAPHICS.TableColumn_Header_ReadinessStatus' : 'READINESS { br } STATUS',
    'GRAPHICS.TableColumn_Header_ProductsQuantity' : 'PRODUCTS QUANTITY',
    'GRAPHICS.TableColumn_Header_MpStatus' : 'MP STATUS',
    'GRAPHICS.TableColumn_Header_ProductType' : 'PRODUCT TYPE',
    'GRAPHICS.TableColumn_Header_Material' : 'MATERIAL',
    'GRAPHICS.TableColumn_Header_PrintingData' : 'PRINTING DATA',
    'GRAPHICS.TableColumn_Header_Color' : 'COLOR { br } (ORACAL)',
    'GRAPHICS.TableColumn_Header_VisibleW' : 'VISIBLE W { br } (MM)',
    'GRAPHICS.TableColumn_Header_VisibleH' : 'VISIBLE H { br } (MM)',
    'GRAPHICS.TableColumn_Header_ActualW' : 'ACTUAL W { br } (MM)',
    'GRAPHICS.TableColumn_Header_ActualH' : 'ACTUAL H { br } (MM)',
    'GRAPHICS.TableColumn_Header_MontageType' : 'MONTAGE TYPE',
    'GRAPHICS.TableColumn_Header_MontageComment' : 'MONTAGE COMMENT',
    'GRAPHICS.TableColumn_Header_OfferPrice' : 'OFFER PRICE',
    'GRAPHICS.TableColumn_Header_MpInternalOrder' : 'MP INTERNAL ORDER №',
    'GRAPHICS.TableColumn_Header_NumberOfLetters' : 'NUMBER OF LETTERS',
    'GRAPHICS.TableColumn_Header_EffectiveArea' : 'EFFECTIVE AREA [M²]',
    'GRAPHICS.TableColumn_Header_CalculatedArea' : 'CALCULATED AREA [M²]',
    'GRAPHICS.TableColumn_Header_GraphicCompanyOrderNumber' : 'GRAPHIC COMPANY ORDER №',
    'GRAPHICS.TableColumn_Header_ProductionPrice' : 'PRODUCTION { br } PRICE (€)',
    'GRAPHICS.TableColumn_Header_MontagePrice' : 'MONTAGE { br } PRICE (€)',
    'GRAPHICS.TableColumn_Header_Total' : 'TOTAL (€)',
    'GRAPHICS.Modal_Text_DeleteGraphics' : 'Do you want to delete this graphics?',
    'GLOBAL.Label_Text_File' : '{ count } File',
    'GLOBAL.Label_Text_Files' : '{ count } Files',
    'GLOBAL.Label_Text_Text' : '{ count } Text',
    'GLOBAL.Label_Text_Texts' : '{ count } Texts',
    'GLOBAL.Action_Menu_Edit' : 'Edit',
    'GLOBAL.Action_Menu_Delete' : 'Delete',
    'GLOBAL.Input_Placeholder_TypeToSearch' : 'Type to search',
    'GLOBAL.CurrentExhibition_PreText_YouAreWorkingOn' : 'You are working on',
    'NAVIGATION.Button_Label_Notifications' : 'Notifications',
    'NAVIGATION.Button_Label_Logout' : 'Log out',
    'GRAPHICS.List_PageTitle' : '{ exhibitionName } graphic list',
    'CHECKLIST.List_PageTitle' : '{ exhibitionName } checklist',
    'STAND.List_PageTitle' : '{ exhibitionName } stand list',
    'NAVIGATION.Button_Text_View' : 'view >',
    'STAND.TableColumn_Group_GeneralInfo' : 'STAND INFO',
    'STAND.TableColumn_Group_Equipment' : 'EQUIPMENT ORDER',
    'STAND.TableColumn_Group_Graphics' : 'GRAPHICS ORDER',
    'STAND.TableColumn_Group_CADInfo' : 'CAD STATUS',
    'STAND.TableColumn_Group_CurrentTask' : 'CURRENT TASK',
    'STAND.TableColumn_Header_Company' : 'COMPANY',
    'STAND.TableColumn_Header_Hall' : 'HALL',
    'STAND.TableColumn_Header_Block' : 'BLOCK',
    'STAND.TableColumn_Header_StandNumber' : 'STAND №',
    'STAND.TableColumn_Header_PROAUF' : 'PROAUF',
    'STAND.TableColumn_Header_StandType' : 'STAND TYPE',
    'STAND.TableColumn_Header_Orientation' : 'ORIENTATION',
    'STAND.TableColumn_Header_Size' : 'SIZE m² (WxH)',
    'STAND.TableColumn_Header_FloorCovering' : 'FLOOR COVERING',
    'STAND.TableColumn_Header_ProjectManager' : 'PROJECT MANAGER',
    'STAND.TableColumn_Header_AutocadEngineer' : 'AUTOCAD ENGINEER',
    'STAND.TableColumn_Header_Comments' : 'COMMENTS',
    'STAND.TableColumn_Header_AdditionalOrder' : 'ADDITIONAL ORDER',
    'STAND.TableColumn_Header_EquipmentComment' : 'COMMENT',
    'STAND.TableColumn_Header_OrdersNumber' : 'ORDERS NUMBER',
    'STAND.TableColumn_Header_CADNumber' : 'CAD NUMBER',
    'STAND.TableColumn_Header_CADStatus' : 'STATUS',
    'STAND.TableColumn_Header_CadChanges' : 'CHANGES',
    'STAND.TableColumn_Header_CadVersion' : 'NEWEST VERSION',
    'STAND.TableColumn_Header_Importance' : 'IMPORTANCE',
    'STAND.TableColumn_Header_AssignedTo' : 'ASSIGNED TO',
    'STAND.TableColumn_Header_TaskStatus' : 'STATUS',
    'STAND.TableColumn_Header_TaskType' : 'TASK TYPE',
    'STAND.TableColumn_Header_TaskDetails' : 'TASK DETAILS',
    'STAND.Action_Menu_Equipment' : 'Equipment',
    'STAND.Action_Menu_Graphics' : 'Graphics',
    'STAND.Action_Menu_Tasks' : 'Tasks',
    'GLOBAL.Label_Text_More' : '+{ count } more',
    'GLOBAL.Label_Text_Version' : 'Version { versionNumber }',
    'STAND.Button_Label_AddStand' : 'Add stand',
    'STAND.Modal_Text_DeleteStand' : 'Do you want to delete { companyName } stand?',
    'GLOBAL.Modal_DefaultText_Delete' : 'Do you want to delete these item?',
    'HALL.TableColumn_Header_Name' : 'NAME',
    'HALL.TableColumn_Header_HallNumber' : 'HALL NUMBER',
    'HALL.TableColumn_Header_NumberOfStands' : 'NUMBER OF STANDS',
    'HALL.TableColumn_Header_HallPlan' : 'HALL PLAN',
    'HALL.Button_Label_Upload' : 'Upload plan',
    'HALL.Button_Label_Download' : 'Download',
    'HALL.Field_Label_HallPlan' : 'Hall plan',
    'HALL.Button_Label_DeleteHall' : 'Delete hall',
    'GLOBAL.Button_Label_SaveChanges' : 'Save changes',
    'GLOBAL.Button_Label_Create' : 'Create',
    'HALL.Field_Label_HallName' : 'HALL NAME',
    'HALL.Field_Label_HallNumber' : 'HALL NUMBER',
    'HALL.Button_Label_AddHall' : 'Add hall',
    'EXHIBITION.Menu_Label_ExhibitionInfo' : 'Exhibition info',
    'EXHIBITION.Menu_Label_Halls' : 'Halls',
    'EXHIBITION.Menu_Label_Team' : 'Team',
    'EXHIBITION.Menu_Label_Statistics' : 'Statistics',
    'EXHIBITION.Page_Title_CreateExhibition' : 'Create exhibition',
    'EXHIBITION.Field_Label_Name' : 'Name',
    'EXHIBITION.Field_Label_AKQ' : 'AKQ',
    'EXHIBITION.Field_Label_ConstructionPeriod' : 'Construction period',
    'EXHIBITION.Field_Label_RunningPeriod' : 'Running period',
    'EXHIBITION.Field_Label_DeconstructionPeriod' : 'Deconstruction period',
    'EXHIBITION.Field_Label_Organizer' : 'Organizer',
    'EXHIBITION.Field_Label_ExhibitionType' : 'Exhibition type',
    'EXHIBITION.Field_Label_Team' : 'Team',
    'EXHIBITION.Field_Label_LeadProjectManager' : 'Lead project manager',
    'EXHIBITION.Field_Label_ConstructionManager' : 'Construction manager',
    'EXHIBITION.Button_Label_DeleteExhibition' : 'Delete Exhibition',
    'USER.Modal_Title_ChooseLeadProjectManager' : 'Choose Lead project manager',
    'USER.Modal_Title_ChooseConstructionManager' : 'Choose Construction manager',
    'GLOBAL.Placeholder_Text_NotSelected' : 'Not selected',
    'USER.Select_Option_ShowJustTeamRole' : 'Show just { teamName } team { teamRole }',
    'USER.Select_Option_RoleFromAllTeams' : '{ teamRole } from all teams',
    'USER.Team_Role_AutocadEngineers' : 'Autocad engineers',
    'USER.Team_Role_Managers' : 'Managers',
    'USER.Team_Role_Members' : 'Members',
    'USER.Team_Role_ProjectManagers' : 'Project Managers',
    'USER.Team_Role_ConstructionManagers' : 'Construction managers',
    'EXHIBITION.TableColumn_Header_Exhibition' : 'Exhibition',
    'EXHIBITION.TableColumn_Header_AKQ' : 'AKQ',
    'EXHIBITION.TableColumn_Header_Status' : 'STATUS',
    'EXHIBITION.TableColumn_Header_Stands' : 'STANDS',
    'EXHIBITION.TableColumn_Header_ConstructionDate' : 'CONSTRUCTION',
    'EXHIBITION.TableColumn_Header_RunningDate' : 'RUNNING',
    'EXHIBITION.TableColumn_Header_DeconstructionDate' : 'DECONSTRUCTION',
    'EXHIBITION.TableColumn_Header_ProjectManager' : 'PROJECT MANAGER',
    'EXHIBITION.TableColumn_Header_OrganizerType' : 'ORGANIZER/EXPO TYPE',
    'EXHIBITION.TableColumn_Header_Area' : 'AREA (SQ.M)',
    'EXHIBITION.TableColumn_Header_Team' : 'TEAM',
    'EXHIBITION.Button_Label_AddExhibition' : 'Add exhibition',
    'EXHIBITION.List_PageTitle_Exhibitions' : 'Exhibitions',
    'EXHIBITION.Modal_Text_DeleteExhibition' : 'Do you want to delete the { exhibitionTitle } exhibition?',
    'STAND.Page_Title_CreateStand' : 'Create Stand',
    'STAND.Menu_Label_StandDetails' : 'Stand Details',
    'STAND.Menu_Label_Equipments' : 'Equipments',
    'STAND.Menu_Label_Graphics' : 'Graphics',
    'STAND.Menu_Label_Tasks' : 'Tasks',
    'STAND.Menu_Label_StandTypeInfo' : 'Stand type info',
    'STAND.Page_Title_Hall' : 'Hall : { hallTitle }',
    'STAND.Page_Title_Block' : 'Block : { block }',
    'STAND.Page_Title_Stand' : 'Stand : { standNumber }',
    'STAND.Form_Title_StandInfo' : 'Stand info',
    'STAND.Field_Label_CompanyName' : 'Company name',
    'STAND.Field_Label_Hall' : 'Hall',
    'STAND.Field_Label_Block' : 'Block',
    'STAND.Field_Label_StandNumber' : 'Stand number',
    'STAND.Field_Label_Proauf' : 'Proauf',
    'STAND.Field_Label_StandType' : 'Stand type',
    'STAND.Field_Label_StandOrientation' : 'Stand Orientation',
    'STAND.Field_Label_Size' : 'Size (from { minSize } to { maxSize } sq.m)',
    'STAND.Field_Label_FloorCovering' : 'Floor covering',
    'STAND.Button_Label_DeleteStand' : 'Delete stand',
    'USER.Modal_Title_ChooseAutocadEngineer' : 'Choose Autocad engineer',
    'USER.Modal_Title_ChooseProjectManager' : 'Choose Project manager',
    'USER.Team_Role_AutocadEngineer' : 'Autocad engineer',
    'USER.Team_Role_ProjectManager' : 'Project manager',
    'GLOBAL.Field_Postfix_M' : 'm',
    'GLOBAL.Field_Postfix_SQM' : 'sq.m',
    'STAND.Modal_Title_ChangeSizeAndType' : 'Change stand type and size',
    'GLOBAL.Button_Label_Change' : 'Change',
    'STAND.Modal_InfoText_ChangesEffectEquipment' : 'These changes can effect default equipment list',
    'STAND.FieldError_Text_AreaBetween' : 'Area must be between { sizeMin } and { sizeMax } sq.m',
    'GLOBAL.Button_Label_Choose' : 'Choose',
    'GLOBAL.Button_Label_Confirm' : 'Confirm',
    'STAND.Text_CADNumber' : 'CAD { cadNumber }',
    'STAND.Field_Label_CadVersion' : 'Cad version',
    'STAND.Text_CADVersion' : 'Version { cadVersion }',
    'STAND.Text_CurrentVersion' : 'Current version',
    'STAND.NoData_Text_CadFile' : 'No CAD File',
    'STAND.NoData_Text_Cads' : 'No available CADs yet',
    'STAND.Block_Title_CAD' : 'Cad',
    'STAND.Text_VersionList' : 'Versions list',
    'STAND.Block_Title_Comments' : 'COMMENTS',
    'STAND.Modal_Text_DeleteComment' : 'Do you want to delete this comment?',
    'STAND.NoData_Text_Comments' : 'No comments yet',
    'STAND.Modal_Title_AddComment' : 'Add comment',
    'STAND.Modal_Title_EditComment' : 'Edit Comment',
    'GLOBAL.Button_Label_Save' : 'Save',
    'STAND.Placeholder_Text_TypeComment' : 'Type comment text',
    'GLOBAL.Button_Label_Add' : 'Add',
    'EQUIPMENT.Block_Title_Comment' : 'COMMENT',
    'EQUIPMENT.NoData_Text_NoComment' : 'No comment yet',
    'EQUIPMENT.TableColumn_Header_EquipmentName' : 'EQUIPMENT NAME',
    'EQUIPMENT.TableColumn_Header_NavisionNumber' : 'NAVISION №',
    'EQUIPMENT.TableColumn_Header_PricePP' : 'PRICE P/P',
    'EQUIPMENT.TableColumn_Header_Default' : 'DEFAULT',
    'EQUIPMENT.TableColumn_Header_Quantity' : 'QUANTITY',
    'EQUIPMENT.Modal_Text_DeleteEquipment' : 'Do you want to delete { equipmentTitle } this equipment?',
    'EQUIPMENT.Order_CollapseLabel_default' : 'Default equipment list',
    'EQUIPMENT.Order_CollapseLabel_additional' : 'Additional equipment list',
    'EQUIPMENT.Modal_Text_EquipmentInfo' : 'Equipment info',
    'EQUIPMENT.Action_Menu_Info' : 'Info',
    'EQUIPMENT.Action_Menu_Substitute' : 'Substitute',
    'EQUIPMENT.Button_Text_AddEquipment' : 'Add equipment',
    'EQUIPMENT.Modal_Title_Substitute' : 'Substitute',
    'EQUIPMENT.Modal_Title_AddEquipment' : 'Add equipment',
    'GLOBAL.Button_Label_Cancel' : 'Cancel',
    'EQUIPMENT.Field_Label_OrganizerName' : 'Organizer name:',
    'EQUIPMENT.Field_Label_Price' : 'Price:',
    'EQUIPMENT.Field_Label_OrganizerNumber' : 'Organizer №:',
    'EQUIPMENT.Field_Label_NavisionNumber' : 'Navision №:',
    'EQUIPMENT.Field_Label_OctacadNumber' : 'Octacad №:',
    'EQUIPMENT.Field_Label_Supplier' : 'Supplier:',
    'EQUIPMENT.Field_Label_SupplierNumber' : 'Supplier №:',
    'GRAPHICS.Text_TotalOrder' : 'There is { totalOrder } graphics order',
    'GRAPHICS.Text_TotalOrders' : 'There are { totalOrder } graphics orders',
    'GRAPHICS.Button_Label_AddOrder' : 'Add order',
    'GRAPHICS.Button_Label_PrintData' : 'Print data ({ dataCount })',
    'GRAPHICS.Field_Label_RuleSet' : 'Rule set',
    'GRAPHICS.Field_Label_ReadinessStatus' : 'Readiness Status',
    'GRAPHICS.Field_Label_MpStatus' : 'MP Status',
    'GRAPHICS.Field_Label_Quantity' : 'Quantity',
    'GRAPHICS.Field_Label_ProductionType' : 'Product type',
    'GRAPHICS.Field_Label_Montage' : 'Montage',
    'GRAPHICS.Field_Label_Material' : 'Material',
    'GRAPHICS.Field_Label_Color' : 'Color (Oracal)',
    'GRAPHICS.Field_Label_VisibleWidth' : 'Visible Width',
    'GRAPHICS.Field_Label_VisibleHeight' : 'Visible Height',
    'GRAPHICS.Field_Label_ActualWidth' : 'Actual Width',
    'GRAPHICS.Field_Label_ActualHeight' : 'Actual Height',
    'GRAPHICS.Field_Label_MontageComment' : 'Montage Comment',
    'GRAPHICS.Field_Label_OfferPrice' : 'Offer price',
    'GRAPHICS.Field_Label_GraphicCompanyOrder' : 'Graphic company order №',
    'GRAPHICS.Field_Label_MPInternalOrder' : 'MP internal order №',
    'GRAPHICS.Field_Label_EffectiveArea' : 'Effective area',
    'GRAPHICS.Field_Label_CalculatedArea' : 'Calculated area',
    'GRAPHICS.Field_Label_NumberOfLetters' : 'Number of letters',
    'GRAPHICS.Field_Label_ProductionPrice' : 'Production price',
    'GRAPHICS.Field_Label_MontagePrice' : 'Montage price',
    'GRAPHICS.Field_Label_Total' : 'Total',
    'GRAPHICS.Field_Label_LayoutReadinessStatus' : 'Readiness status',
    'GRAPHICS.Block_Title_ApprovalLayout' : 'Approval layout',
    'GRAPHICS.Button_Label_UploadVersion' : 'Upload version',
    'GRAPHICS.Modal_Title_PrintData' : 'Print data of order №{ number }',
    'GRAPHICS.Block_Title_PrintingFiles' : 'Printing files',
    'GRAPHICS.Block_Title_PrintingText' : 'Printing text',
    'GRAPHICS.Action_Menu_UploadFile' : 'Upload file',
    'GRAPHICS.Action_Menu_AddFileLink' : 'Add file link',
    'GRAPHICS.Modal_Title_AddLink' : 'Add link',
    'GRAPHICS.Field_Label_LinkTitle' : 'Title',
    'GRAPHICS.Field_Label_LinkUrl' : 'URL',
    'GRAPHICS.Field_Label_Text' : 'Text',
    'GRAPHICS.Button_Label_AddText' : 'Add text',
    'GRAPHICS.Modal_Title_CreateGraphicsOrder' : 'Create Graphics order',
    'GLOBAL.Notification_Message_FileUploaded' : 'File uploaded successfully',
    'GLOBAL.Notification_Message_PleasWaitWhileFileUploaded' : 'Please wait while the file is being uploaded',
    'GRAPHICS.Modal_Title_EditLink' : 'Edit link',
    'GLOBAL.Modal_Text_DeleteLink' : 'Do you want delete link?{ br }{ link }',
    'GLOBAL.Modal_Text_DeleteFile' : 'Do you want delete file?{ br }{ file }',

    'GLOBAL.Button_Label_Filters' : 'Filters { count }',
    'GLOBAL.Modal_Title_Filters' : 'Filters ({ count })',
    'STAND.Select_Option_Filter_hall' : 'Hall',
    'STAND.Select_Option_Filter_block' : 'Block',
    'STAND.Select_Option_Filter_projectManager' : 'Project manager',
    'STAND.Select_Option_Filter_autocadEngineer' : 'Autocad Engineer',
    'STAND.Select_Option_Filter_cadStatus' : 'Cad status',
    'STAND.Select_Option_Filter_taskImportance' : 'Task importance',
    'STAND.Select_Option_Filter_taskStatus' : 'Task status',
    'STAND.Select_Option_Filter_taskType' : 'Task type',
    'STAND.Select_Option_Filter_taskAssignedTo' : 'Task assigned to',
    'GLOBAL.Field_Label_ChooseFilter' : 'Choose filter',
    'EXHIBITION.Select_Option_Filter_projectManager' : 'Project manager',
    'NAVIGATION.Menu_Label_TaskList' : 'Task{ br }list',
    'TASK.List_PageTitle' : '{ exhibitionName } task list',
    'TASK.TableColumn_Group_StandInfo' : 'Stand INFO',
    'TASK.TableColumn_Group_TaskInfo' : 'Task INFO',
    'EXHIBITION.Select_Option_Filter_status' : 'Status',
    'EXHIBITION.Select_Option_Filter_organizer' : 'Organizer',
    'EXHIBITION.Select_Option_Filter_exhibitionType' : 'Exhibition type',
    'EXHIBITION.Select_Option_Filter_team' : 'Team',
    'EXHIBITION.Select_Option_Status_new' : 'New',
    'EXHIBITION.Select_Option_Status_in_process' : 'In process',
    'EXHIBITION.Select_Option_Status_finished' : 'Finished',
    'GRAPHICS.Select_Option_Filter_hall' : 'Hall',
    'GRAPHICS.Select_Option_Filter_block' : 'Block',
    'GRAPHICS.Select_Option_Filter_companyName' : 'Company name',
    'GRAPHICS.Select_Option_Filter_standNumber' : 'Stand number',
    'GRAPHICS.Select_Option_Filter_status' : 'Readiness status',
    'GRAPHICS.Select_Option_Filter_mpStatus' : 'Status',

    'TASK.Select_Option_Filter_hall' : 'Hall',
    'TASK.Select_Option_Filter_block' : 'Block',
    'TASK.Select_Option_Filter_companyName' : 'Company name',
    'TASK.Select_Option_Filter_standNumber' : 'Stand number',
    'TASK.Select_Option_Filter_projectManager' : 'Project manager',
    'TASK.Select_Option_Filter_autocadEngineer' : 'Autocad Engineer',
    'TASK.Select_Option_Filter_cadStatus' : 'Cad status',
    'TASK.Select_Option_Filter_cadNumber' : 'Cad number',
    'TASK.Select_Option_Filter_taskImportance' : 'Task importance',
    'TASK.Select_Option_Filter_taskStatus' : 'Task status',
    'TASK.Select_Option_Filter_taskType' : 'Task type',
    'TASK.Select_Option_Filter_taskAssignedTo' : 'Task assigned to',

    'TASK.TableColumn_Header_ClientName' : 'CLIENT NAME  { br } HALL / STAND / BLOCK',
    'STAND.Modal_Title_ChangeCurrentCad' : 'Change current CAD',
    'GLOBAL.Button_Label_Use' : 'Use',
    'GLOBAL.Button_Label_ResetFilter' : 'Reset filter',
    'GLOBAL.Action_Menu_DeletePreset' : 'Delete preset',
    'GLOBAL.Action_Menu_Make_public' : 'Make Public',
    'GLOBAL.Action_Menu_Make_private' : 'Make Private',
    'GLOBAL.Modal_Text_DeleteFilterPreset' : 'Do you want to delete { filterPresetName } filter preset?',
    'GLOBAL.Modal_Title_UseFiltersPreset' : 'Use filters preset',
    'GLOBAL.Modal_Title_SaveFiltersPreset' : 'Save filters preset',
    'GLOBAL.Button_Label_UseFiltersPreset' : 'Use filters preset',
    'GLOBAL.Button_Label_SaveFiltersPreset' : 'Save filters preset',
    'GLOBAL.Option_Label_DefaultFilter' : 'Default filter preset',

    /** NEW */
    'TASK.TableColumn_Group_CurrentTaskInfo' : 'Current Task',
    'TASK.TableColumn_Header_Details' : 'DETAILS',
    'NAVIGATION.Menu_Label_EquipmentList' : 'Equipment list',
    'EQUIPMENT.List_PageTitle' : '{ exhibitionName } equipment list',
    'STAND.TableColumn_Header_CadNumber' : 'Cad number',
    'EQUIPMENT.TableColumn_Header_ClientName' : 'CLIENT NAME  { br } HALL / STAND / BLOCK',
    'EQUIPMENT.TableColumn_Group_StandInfo' : 'Stand INFO',
    'EQUIPMENT.TableColumn_Header_CadNumber' : 'CAD NUMBER',

    'EQUIPMENT.Select_Option_Filter_hall' : 'Hall',
    'EQUIPMENT.Select_Option_Filter_block' : 'Block',
    'EQUIPMENT.Select_Option_Filter_companyName' : 'Company name',
    'EQUIPMENT.Select_Option_Filter_standNumber' : 'Stand number',
    'EQUIPMENT.Select_Option_Filter_cadNumber' : 'Cad number',
};

export default english;