import { TTeam, TUserRelation } from "./user-gql-types";

import { TFilterTypeName, TLocales } from "common/types";

export const enum EWhereOperator {
    EQ = 'EQ',
    NEQ = 'NEQ',
    GT = 'GT',
    GTE = 'GTE',
    LT =  'LT',
    LTE = 'LTE',
    LIKE = 'LIKE',
    NOT_LIKE = 'NOT_LIKE',
    IN = 'IN',
    NOT_IN = 'NOT_IN',
    BETWEEN = 'BETWEEN',
    NOT_BETWEEN = 'NOT_BETWEEN',
    IS_NULL = 'IS_NULL',
    IS_NOT_NULL = 'IS_NOT_NULL'
}

const enum EImageSizes {
    original = 'original',
    '200x200' = '200x200',
    '600x600' = '600x600',
    '1024x1024' = '1024x1024'
}

export type TWhereCondition = {
    column   : string
    operator : keyof typeof EWhereOperator
    value    : string | string[] | number | boolean | null
    label   ?: string
    AND     ?: TWhereCondition[]
    OR      ?: TWhereCondition[]
    HAS     ?: TWhereCondition[]
};

export type TOrderByClause = {
    column   : string
    order    : "ASC" | "DESC"
};

export interface IQueryVariables{
    text    ?: string
    where   ?: TWhereCondition
    orderBy ?: TOrderByClause[]
    first   ?: number
    after   ?: string
}

export interface IQueryVariablesPaginator{
    text    ?: string
    where   ?: TWhereCondition
    orderBy ?: TOrderByClause[]
    first   ?: number
    page    ?: number
}

export type TNode<NodeType> = {
    node : NodeType
};

export type TCursorData<NodeType> = {
    pageInfo : TPageInfo
    edges    : TNode<NodeType>[]
};

export type TPaginatorData<NodeType> = {
    paginatorInfo : TPaginatorInfo
    data          : NodeType[]
};

export type TID = string | number;

export type TPayload = {
    label   : string
    message : string
};

export type TPageInfo = {
    hasNextPage      : boolean
    hasPreviousPage ?: boolean
    startCursor      : string
    endCursor        : string
    total            : number
    count           ?: number
    currentPage      : number
    lastPage        ?: boolean
};

export type TPaginatorInfo = {
    hasMorePages  : boolean
    firstItem     : number
    lastItem      : number
    total         : number
    count        ?: number
    currentPage   : number
    lastPage     ?: number
    perPage      ?: number
};

export type TImageData = {
    id          : number
    server_name : string
    title       : string
    sizes       : {
        [ key in EImageSizes ]? : string
    }
};

export type TAttachment = {
    readonly id            : number
             server_name   : string
             original_name : string
             title         : string
             description   : string
             path          : string
             extension     : string
};

export type TVariableItem = {
    readonly id         : number
             group_name : string
             lang       : TLocales
             slug       : string
             value      : string
             img_path   : string
    readonly created_at : string
    readonly updated_at : string
};

export type TVariableListCursorData = TCursorData<TVariableItem>;

export type TFilterStatus = 'public' | 'private' | 'default';
export type TFilterType = 'request' | 'column';

export type TTableFilterItem = {
    id       : TID
    title    : string
    query    : TFilterTypeName
    type     : TFilterType
    status   : TFilterStatus
    data     : any
    team_id  : TID
    user_id  : TID
    relUser  : TUserRelation
    relTeam  : TTeam
};

export type TTableFilterInput = {
    id      ?: TID
    title    : string
    query    : TFilterTypeName
    type     : TFilterType
    status   : TFilterStatus
    data     : any
    team_id ?: TID
    user_id ?: TID
};

export type TTableFilterItemPayload = TPayload & { tableFilter: TTableFilterItem };
export type TTableFilterPaginatorData = TPaginatorData<TTableFilterItem>;

export type TMetaGroup = 'currentFilter' | 'defaultFilter';
export type TMetaType = 'array' | 'json' | 'string' | 'number' | TID;
export type TMetaModel = 'UserMeta';

export type TModelMetaInput = {
    id         : TID
    model_id   : TID
    meta_key   : string
    meta_type  : TMetaType
    meta_value : any
    meta_group : TMetaGroup
    model      : TMetaModel
};

export type TMetaItem = {
    id         : TID
    user_id    : TID
    meta_key   : string
    meta_type  : TMetaType
    meta_value : any
    meta_group : TMetaGroup
};

export type TMetaModelItemPayload = TPayload & { model: TMetaItem };

export type TTableFilterDefaultPayload = TPayload & {
    tableFilters: TTableFilterItem[]
    userMetas : TMetaItem[]
};