import React from "react";

import { generatePath, Link } from "react-router-dom";

import { ROUTES } from "components/routes";
import Localize from "components/service/Localize";

import type { TNode } from "graphql/type/common-gql-types";
import type { TEquipmentOrderItem } from "graphql/type/equipment-gql-types";
import type { TStandItem } from "graphql/type/stand-gql-types";
import type { TStandTypeRelation } from "graphql/type/stand-type-gql-types";
import type { FixedType } from "rc-table/lib/interface";


const columns  = [
    {
        title :
            <>
                <Localize wrap tag="div" className="table-section-label">
                    EQUIPMENT.TableColumn_Group_StandInfo
                </Localize>
                <Localize br={<br />}>
                    EQUIPMENT.TableColumn_Header_ClientName
                </Localize>
            </>
        ,
        dataIndex : "standInfo",
        columnIndex : "TITLE",
        width : 240,
        fixed : 'left' as FixedType,
        className : "general-info"
        //sorter: true,
    },
    {
        title :
            <Localize>
                EQUIPMENT.TableColumn_Header_CadNumber
            </Localize>,
        dataIndex : "cadNumber",
        columnIndex : "CAD_NUMBER",
        className : "general-info",
        width : 120,
    },
];
/*
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
* */

const columnsMap = (allEquipments : TEquipmentOrderItem[]) => {

    if(allEquipments.length){
        const additionalColumns = allEquipments.map( ( item, idx ) => {

            const [ equipment ] = item.relEquipment;
            const ColumnTitle = () => {
                return (
                    <div className="column-title">
                        <p className="title">{equipment.title}</p>
                        <p className="sku">{equipment.supplier_article}</p>
                        <p className="total">{ item.total_qty }</p>
                    </div>
                );
            };

            return ({
                title : idx === 0 ?
                    <>
                        <Localize wrap tag="div" className="table-section-label">
                            STAND.TableColumn_Group_Equipment
                        </Localize>
                        <ColumnTitle />
                    </> :
                    <ColumnTitle />,
                dataIndex : `equipment_${ equipment.group_id }`,
                columnIndex : `EQUIPMENT_ORDER_${ equipment.group_id }`,
                className : 'equipment',
                width : 200,
            });
        });

        return [ ...columns, ...additionalColumns ];
    }

    return columns;
};

const dataMap = ( stands : TNode<TStandItem<TStandTypeRelation>>[] ) => {

    return stands.map( ({ node  }) => {

        const {
            id,
            ee_id,
            hall_title,
            stand_number,
            block,
            company_name,
            relCad,
            relStandEquipmentOrders : equipments = [],
        } = node;

        const equipmentsColumnData : { [ key : string ] : number } = {};

        equipments.forEach(
            ( item ) => {

                const equipment = item.relEquipment[0];

                const columnIndex = `equipment_${ equipment.group_id }`;

                if(equipmentsColumnData[columnIndex]){
                    equipmentsColumnData[columnIndex] += item.qty;
                } else {
                    if(item.qty > 0){
                        equipmentsColumnData[columnIndex] = item.qty;
                    }
                }

            }
        );

        return{
            key : id,
            standInfo :
                <>
                    <Link className="item-link"
                            to={
                                generatePath(
                                    ROUTES.STAND_ITEM_EQUIPMENT_FULL,
                                    {
                                        eeId : Number(ee_id),
                                        standId : Number(id)
                                    }
                                )
                            }
                            state       = {{ fromPage : 'task-list' }}
                    >
                        { company_name }
                    </Link>
                    <p className="stand-info">
                        { hall_title } / { stand_number } / { block }
                    </p>
                </>,
            cadNumber : relCad ? relCad.cad_number : <>—</>,
            ...equipmentsColumnData
        };

    });
};

const StandEquipmentListTableHelper = {
    columns,
    columnsMap,
    totalColumnWidth : (tableColumns ?: any[] ) : number => {
        return (tableColumns || columns).reduce( (total, item) => total + item.width + 3, 0) + 20;
    },
    dataMap,
};

export default StandEquipmentListTableHelper;