import useFilterPresetMutationHook from "./use-filter-preset-mutation-hook";
import useMetaSetHook from "./use-meta-set-hook";
import { reactiveVarHelper } from "common/helpers";

import type { TFilterTypeName } from "common/types";
import type { IFilterReactiveVar } from "graphql/reactive-variables";
import type { TUser } from "graphql/type/user-gql-types";

type TUseFilterSaveProps = {
    filterTypeName : TFilterTypeName
};

const useFilterSaveHook  = (
    {
        filterTypeName,
    } : TUseFilterSaveProps) => {

    const me = reactiveVarHelper().get('me') as TUser;

    const rVarFilter = reactiveVarHelper('filter'),
          {
              currentPresets,
              defaultPresets,
              metaFilters
          } = rVarFilter.get('all') as IFilterReactiveVar;

    const defaultPreset = defaultPresets[filterTypeName],
          currentPreset = currentPresets[filterTypeName],
          currentMeta = metaFilters.find(
              ( item ) => item.meta_group === 'currentFilter' && item.meta_key === filterTypeName
          ),
          defaultIsCurrent = defaultPreset && currentPreset && defaultPreset.id === currentPreset.id;

    const { loading : loadingMetaSave, metaSet } = useMetaSetHook({
        onUpdate : (model) => {
            rVarFilter.set(
                metaFilters.map( ( meta ) => meta.id === model.id ? model : meta)
            );
        }
    });

    const { loading, tableFilterAction } = useFilterPresetMutationHook(
        {
            isCreate : false,
            onUpdate : ( tableFilter ) => {

                rVarFilter.set({
                    defaultPreset : {
                        ...defaultPreset,
                        [ filterTypeName ] : tableFilter
                    },
                    currentPreset : {
                        ...currentPreset,
                        [ filterTypeName ] : tableFilter
                    }
                });

            },
        }
    );

    return {
        loading : loading || loadingMetaSave,
        filterSave : (data: string) => {

            if(currentMeta && !defaultIsCurrent && currentPreset?.data !== data && !loadingMetaSave){
                metaSet({
                    variables : {
                        input : {
                            id : currentMeta.id,
                            model : 'UserMeta',
                            meta_group : currentMeta.meta_group,
                            meta_key : currentMeta.meta_key,
                            meta_type : currentMeta.meta_type,
                            model_id : me.id,
                            meta_value : defaultPreset!.id
                        }
                    }
                });
            }

            if(!loading && defaultPreset){
                tableFilterAction({
                    variables : {
                        input : {
                            id : defaultPreset.id,
                            title : defaultPreset.title,
                            data : data,
                            type : defaultPreset.type,
                            query : filterTypeName,
                            status : defaultPreset.status,
                        }
                    }
                });
            }
        }
    };
};


export default useFilterSaveHook;