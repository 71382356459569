import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";
import { Loader } from "components/request-result";


const EyeOpenSvg : React.FC = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <path d="M46.4,23.73c-8.11-8.33-16.47-12.25-24.89-11.63C9.06,13.01,1.32,23.51,1,23.96c-.61.84-.48,2,.3,2.68,7.46,6.55,15.17,9.86,22.95,9.86.61,0,1.22-.02,1.84-.06,12.28-.82,20.07-9.61,20.4-9.98.68-.78.65-1.96-.08-2.71ZM23.79,29.7c-3.06,0-5.54-2.49-5.54-5.54s2.49-5.54,5.54-5.54,5.54,2.49,5.54,5.54-2.49,5.54-5.54,5.54ZM17.65,16.85c-2.08,1.75-3.41,4.37-3.41,7.3s1.41,5.7,3.59,7.45c-4.16-1.14-8.31-3.38-12.42-6.72,1.97-2.18,6.3-6.29,12.23-8.03ZM29.31,31.93c2.43-1.73,4.03-4.57,4.03-7.77,0-2.62-1.06-4.99-2.77-6.72,3.85,1.45,7.72,4.02,11.57,7.7-2.12,1.93-6.66,5.44-12.82,6.79Z"/>
    </svg>
);

const EyeClosedSvg: React.FC = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <path d="M46.4,23.17c-3.59-3.69-7.24-6.51-10.91-8.46l5.91-5.91c.78-.78.78-2.05,0-2.83-.78-.78-2.05-.78-2.83,0l-7.01,7.01c-3.34-1.2-6.69-1.69-10.06-1.44C9.06,12.45,1.32,22.94,1,23.39c-.61.84-.48,2,.3,2.68,3.38,2.97,6.82,5.26,10.29,6.89l-5.76,5.76c-.78.78-.78,2.05,0,2.83.39.39.9.59,1.41.59s1.02-.2,1.41-.59l6.99-6.99c2.85.91,5.73,1.36,8.61,1.36.61,0,1.22-.02,1.84-.06,12.28-.82,20.07-9.61,20.39-9.98.68-.78.65-1.96-.08-2.71ZM29.34,23.59c0,3.06-2.49,5.54-5.54,5.54-.8,0-1.56-.17-2.25-.48l7.31-7.31c.31.69.48,1.45.48,2.24ZM18.72,25.82c-.3-.68-.47-1.44-.47-2.23,0-3.06,2.49-5.54,5.54-5.54.79,0,1.55.17,2.23.47l-7.3,7.3ZM5.43,24.32c1.98-2.18,6.32-6.29,12.22-8.03-2.08,1.75-3.4,4.37-3.4,7.29,0,1.91.57,3.68,1.54,5.17l-1.17,1.17c-3.08-1.27-6.14-3.14-9.19-5.61ZM29.31,31.36c2.43-1.73,4.03-4.57,4.03-7.77,0-1.91-.57-3.69-1.54-5.18l.7-.7c3.21,1.52,6.43,3.8,9.63,6.87-2.12,1.93-6.66,5.44-12.82,6.79Z"/>
    </svg>
);


const EyeIcon: FunctionComponent<IIcon> = ({ className, type, loading }): JSX.Element => {

    if (loading) {
        return <Loader/>;
    }

    if (type === 'closed') {
        return <Icon component={EyeClosedSvg} className={className}/>;
    }

    return <Icon component={EyeOpenSvg} className={className}/>;

};


export default EyeIcon;