
import { EStandFilterFieldTypes } from "../stand-types";

import type { TStandFilterGroups, TStandFilters } from "../stand-types";
import type { TFilterFieldItem, TFilterFields } from "common/types";
import type { TWhereCondition } from "graphql/type/common-gql-types";


const standFilterParse = (filters : TStandFilters) : TFilterFieldItem<EStandFilterFieldTypes>[] => {

    const fields : TFilterFieldItem<EStandFilterFieldTypes>[] = [];

    (Object.keys(filters) as Array<TStandFilterGroups | 'text'>).forEach( ( group ) => {

        if(group !== 'text'){
            const filterGroup = filters[group] as Omit<TFilterFields, 'text'>;

            Object.keys(filterGroup).forEach(( filterItemKey ) => {

                const item = filterGroup[filterItemKey] as TWhereCondition;
                let type;

                switch(`${ group }_${filterItemKey}`){
                    case 'whereTasks_taskType':       type = EStandFilterFieldTypes.TASK_TYPE; break;
                    case 'whereTasks_taskAssignedTo': type = EStandFilterFieldTypes.TASK_ASSIGNED_TO; break;
                    case 'whereTasks_taskStatus':     type = EStandFilterFieldTypes.TASK_STATUS; break;
                    case 'whereTasks_taskImportance': type = EStandFilterFieldTypes.TASK_IMPORTANCE; break;
                    case 'whereCad_cadStatus':        type = EStandFilterFieldTypes.CAD_STATUS; break;
                    case 'where_projectManager':      type = EStandFilterFieldTypes.PROJECT_MANAGER; break;
                    case 'where_hall':                type = EStandFilterFieldTypes.HALL; break;
                    case 'where_block':               type = EStandFilterFieldTypes.BLOCK; break;
                    case 'where_autocadEngineer':     type = EStandFilterFieldTypes.AUTOCAD_ENGINEER; break;
                }

                const value = typeof item.value === 'string' ? item.value.replace('%', '') : item.value;

                fields.push({
                    key : Date.now().toString(),
                    type : type!,
                    saveable : true,
                    label : item.label,
                    value : value as string
                });
            });
        }
    });

    return fields;
};

const standFilterBuild = (fields : TFilterFieldItem<EStandFilterFieldTypes>[]): Omit<TStandFilters, 'text'> => {

    const where: TStandFilters["where"] = {},
        whereCad : TStandFilters["whereCad"] = {},
        whereTasks : TStandFilters["whereTasks"] = {};

    fields.forEach( (item) => {

        if(item.saveable && item.value !== '') {
            const filter : TWhereCondition = {
                column : '',
                operator : "EQ",
                value : item.value,
                label : item.label
            };

            switch( item.type ){
                case EStandFilterFieldTypes.TASK_TYPE:
                    whereTasks[item.type] = { ...filter, column : 'TYPE' };
                break;

                case EStandFilterFieldTypes.TASK_ASSIGNED_TO:
                    whereTasks[item.type] = { ...filter, column : 'ASSIGNED_TO_ID' };
                break;

                case EStandFilterFieldTypes.TASK_STATUS:
                    whereTasks[item.type] = { ...filter, column : 'STATUS' };
                break;

                case EStandFilterFieldTypes.TASK_IMPORTANCE:
                    whereTasks[item.type] = { ...filter, column : 'IMPORTANCE' };
                break;

                case EStandFilterFieldTypes.PROJECT_MANAGER:
                    where[item.type] = { ...filter, column : 'PROJECT_MANAGER_ID' };
                break;

                case EStandFilterFieldTypes.HALL:
                    where[item.type] = { ...filter, column : 'HALL_ID' };
                break;

                case EStandFilterFieldTypes.CAD_STATUS:
                    whereCad[item.type] = { ...filter, column : 'STATUS' };
                break;

                case EStandFilterFieldTypes.BLOCK:
                    where[item.type] = {
                        ...filter,
                        column : 'BLOCK',
                        operator : 'LIKE',
                        value : `${item.value}%`
                    };
                break;

                case EStandFilterFieldTypes.AUTOCAD_ENGINEER:
                    where[item.type] = { ...filter, column : 'AUTOCAD_ENGINEER_ID' };
                break;
            }
        }
    });

    return {
        ...(Object.keys(where).length > 0 && { where }),
        ...(Object.keys(whereCad).length > 0 && { whereCad }),
        ...(Object.keys(whereTasks).length > 0 && { whereTasks }),
    };
};


const standFilterHelper = {
    parse : standFilterParse,
    build : standFilterBuild
};

export default standFilterHelper;