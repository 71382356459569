import { InMemoryCache } from "@apollo/client";

import { default as rVar } from "./reactive-variables";


const cache: InMemoryCache = new InMemoryCache({
    typePolicies : {
        Query : {
            fields : {
                isLoggedIn : {
                    read () {
                        return !!localStorage.getItem("authToken");
                    }
                },
                privateParams : {
                    read () {
                        return rVar.private.handler();
                    }
                },
                publicParams : {
                    read () {
                        return rVar.public.handler();
                    }
                },
                filterParams : {
                    read (){
                        return rVar.filter.handler();
                    }
                }
            },
        },
    },
});

export default cache;